import { ethers } from 'ethers';
import {
  SignerOrProviderLike,
  CrossChainMessenger,
  ETHBridgeAdapter,
  StandardBridgeAdapter,
} from "@eth-optimism/sdk"; // eslint-disable-line

export const erc20ABI = [
  // balanceOf
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: 'balance', type: 'uint256' }],
    type: 'function',
  },
];

export const l1Contracts = {
  StateCommitmentChain: ethers.constants.AddressZero,
  CanonicalTransactionChain: ethers.constants.AddressZero,
  BondManager: ethers.constants.AddressZero,
  AddressManager: '0x96Be56ECcb7e60E656E135600233C42d809aFe01', // Lib_AddressManager.json
  L1CrossDomainMessenger: '0xa3B90CD0A14AB26398626C578e0bF0145A169B7d', // Proxy__OVM_L1CrossDomainMessenger.json
  L1StandardBridge: '0x0094BF370D46d3E318127Eed783Bec46414e6951', // Proxy__OVM_L1StandardBridge.json
  OptimismPortal: '0xD84600Faf06c630C33809511Dd7481E7f11baC6A', // OptimismPortalProxy.json
  L2OutputOracle: '0x758502f01B613287731c0788f1fE4D5C4C19B79B', // L2OutputOracleProxy.json
};

const bridges = {
  Standard: {
    l1Bridge: l1Contracts.L1StandardBridge,
    l2Bridge: '0x4200000000000000000000000000000000000010',
    Adapter: StandardBridgeAdapter,
  },
  ETH: {
    l1Bridge: l1Contracts.L1StandardBridge,
    l2Bridge: '0x4200000000000000000000000000000000000010',
    Adapter: ETHBridgeAdapter,
  },
};

export const erc20Addrs = {
  l1: '0x45982AE5C1dd27d87c5b2D2aEfE756Ae27607FF3', // USDC goerli
  l2: '0x362bbce159C14060c045565fF31d2b7d1282bf35', // USDC karak l2
};

export const createCrossChainMessenger = async (
  l1Signer: SignerOrProviderLike,
  l2Signer: SignerOrProviderLike
) => {
  const crossChainMessenger = new CrossChainMessenger({
    bedrock: true,
    l1ChainId: 5, // Goerli eth value chain id - change to this for dynamic support -> await l1Signer.getChainId()
    l2ChainId: 2511, // Goerli karak value chain id
    l1SignerOrProvider: l1Signer,
    l2SignerOrProvider: l2Signer,
    contracts: {
      l1: l1Contracts,
    },
    bridges,
  });

  return crossChainMessenger;
};
