import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import moment from 'moment';
import { ToolTip } from '../ToolTip/ToolTip';
import { DECIMALS_18, VaultDetails } from '../../constants/constants';
import { PROVIDE_PROTECTION } from '../../constants/BannerDetails';
import { activeVault } from '../../constants/Vault';
import { useConfig } from '../../contexts';
import { getVaultLiquidity } from '../../utils/vaultLiquidity';
import { Address } from 'wagmi';
// import {
//   calculateLeverage,
//   mapRawAmountToUi,
//   sigFigFormat,
// } from '../../utils/helpers';

const GridBox = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  margin-right: auto;
  grid-column-gap: 5rem;
  grid-row-gap: 2rem;

  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;

  @media only screen and (max-width: 1473px) {
    margin-left: 0rem;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    margin-top: 0rem;
    justify-content: left;
    grid-column-gap: 15%;
    grid-row-gap: 2rem;
    align-items: normal;
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 499px) {
    grid-template-columns: auto;
  }
`;

const InfoContainer = styled.div`
  margin-right: auto;
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
  opacity: 0.65;
`;

const SmallText = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
  font-weight: 600;
`;

export const VaultDetailsGrid: React.FC = () => {
  const vaultCapText = 'VAULT CAPACITY';
  const riskAversionText = 'RISK AVERSION';
  const leverageText = 'LEVERAGE';
  const [totalCapacity, setTotalCapacity] = useState<number>(0);
  const config = useConfig();
  useEffect(() => {
    const updateTotalCapacity = async () => {
      const resp = await getVaultLiquidity(activeVault.address as Address, config); 
      setTotalCapacity((Number(resp.totalUnderwriterValueInEth)/Number(DECIMALS_18)));
    }
    updateTotalCapacity();
  }, [config])

  return (
    <GridBox>
      <InfoContainer>
        <Text>
          UNDERWRITING ASSET
          <ToolTip tooltipText={VaultDetails.UWA} />
        </Text>
        <SmallText>
          {PROVIDE_PROTECTION.BANNER.UNDERWRITING_ASSET}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {vaultCapText}
          <ToolTip tooltipText={VaultDetails.VC} />
        </Text>
        <SmallText>
           {`${totalCapacity} ETH`}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {riskAversionText}
          <ToolTip tooltipText={VaultDetails.RA} />
        </Text>
        <SmallText>
          {PROVIDE_PROTECTION.BANNER.RISK_AVERSION}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          NETWORK
          <ToolTip tooltipText={VaultDetails.NET} />
        </Text>
        <SmallText>
          {PROVIDE_PROTECTION.BANNER.NETWORK}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {leverageText}
          <ToolTip tooltipText={VaultDetails.LVG} />
        </Text>
        <SmallText>
          {PROVIDE_PROTECTION.BANNER.LEVERAGE}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          EXPIRATION DATE
          <ToolTip tooltipText={VaultDetails.EXP} />
        </Text>
        <SmallText>
          {activeVault.expiry}
        </SmallText>
      </InfoContainer>
    </GridBox>
  );
};
