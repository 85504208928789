import React, { useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Button, Collapse } from '@chakra-ui/react';

interface Props {
  type: string;
  description?: string;
  risksDesc?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.75rem;

  @media only screen and (max-width: 1149px) {
    width: 100%;
  }
`;

const Heading = styled.p`
  font-size: 1.5rem;
  font-family: Helvetica Neue 700;
  color: var(--text-primary-white);
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const SMarkdown = styled.div`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 500;
  & a {
    text-decoration: underline;
    font-family: Helvetica Neue 500;
  }
`;

const SButton = styled(Button)`
  display: inline;
  background: transparent !important;
  padding: 0 !important;
  color: var(--components-primary-blue) !important;

  &:hover {
    filter: brightness(115%);
  }

  &:focus {
    background-color: transparent;
    box-shadow: none !important;
  }
`;

const TEXT_COLLAPSE_LENGTH_THRESHOLD = 350;

// eslint-disable-next-line react/require-default-props
function CollapsingText({ text = '' }: { text?: string }) {
  const [readMore, setReadMore] = useState(false);
  const tooLong = text.length > TEXT_COLLAPSE_LENGTH_THRESHOLD;

  if (tooLong && text) {
    return (
      <>
        <Collapse startingHeight="7.5rem" in={readMore}>
          <ReactMarkdown>{text}</ReactMarkdown>
        </Collapse>
        <SButton onClick={() => setReadMore(!readMore)}>
          Read {readMore ? 'Less' : 'More'}
        </SButton>
      </>
    );
  }

  return text ? <ReactMarkdown>{text}</ReactMarkdown> : null;
}

export const InfoDesc: React.FC<Props> = ({ type, description, risksDesc }) => {
  if (type === 'pool') {
    return (
      <Container>
        <Heading>Overview</Heading>
        <SMarkdown>
          {description && <ReactMarkdown>{description}</ReactMarkdown>}
        </SMarkdown>
        <br />
        <div style={{ marginBottom: '1rem' }} />
        <Heading>Policyholder Risks</Heading>
        <SMarkdown>
          {risksDesc && <ReactMarkdown>{risksDesc}</ReactMarkdown>}
        </SMarkdown>
      </Container>
    );
  }

  return (
    <Container>
      <Heading>Overview</Heading>
      <SMarkdown>
        <CollapsingText text={description} />
      </SMarkdown>
      <div style={{ marginBottom: '1rem' }} />
      <Heading>Underwriter Risks</Heading>
      <SMarkdown>
        <CollapsingText text={risksDesc} />
      </SMarkdown>
    </Container>
  );
};

InfoDesc.defaultProps = {
  description: undefined,
  risksDesc: undefined,
};
