import React, { createContext, useContext, useEffect, useState } from 'react';
// import {
//   PoolStatus,
//   VaultStatus,
//   UnderwritingPositionType,
// } from '../constants/constants';
import { PolicyholderPosition, UnderwriterPosition } from '../types/types';
import { Maybe } from '../utils/maybe';
import { useAccount } from 'wagmi';

export type PortfolioPageContextValue = {
  policyholderPositions: PolicyholderPosition[];
  underwriterPositions: UnderwriterPosition[];
};

export const PortfolioPageContext = createContext<
  Maybe<PortfolioPageContextValue>
>(Maybe.none());

// function getPoolStatus(pool: PoolWithVaultAndChain, expiry: Date) {
//   const hacked = pool.state.defaultStatus === PoolDefaultStatus.Default;
//   const paused = pool.vault.base.state.isPaused;
//   const expired = expiry.getTime() < Date.now();
//   if (hacked) return PoolStatus.Hacked;
//   if (paused) return PoolStatus.Paused;
//   return expired ? PoolStatus.Closed : PoolStatus.Open;
// }

// function getVaultStatus(vault: VaultWithChain, expiry: Date) {
//   const paused = vault.base.state.isPaused;
//   const expired = expiry.getTime() < Date.now();
//   if (paused) return VaultStatus.Paused;
//   return expired ? VaultStatus.Expired : VaultStatus.Open;
// }

// function validPositionBalance(balance: string | undefined): boolean {
//   return !!balance && BigInt(balance) > BigInt(0);
// }

// function getUnderwriterPositions(
//   onChainData: Maybe<OnChainDataContextValue>,
//   cmsData: Maybe<CMSDataContextValue>,
//   positionType: UnderwritingPositionType
// ): UnderwriterPosition[] {
//   return onChainData
//     .map((d) =>
//       d.vaults
//         .filter((vault) =>
//           positionType === UnderwritingPositionType.Standard
//             ? validPositionBalance(
//                 vault.base.state.underwritingPosition.standard.balance
//               )
//             : validPositionBalance(
//                 vault.base.state.underwritingPosition.rollover.balance
//               )
//         )
//         .map((v) => {
//           const cmsVault = cmsData.flatMap((data) =>
//             Maybe.from(data.vaults.find((vault) => vault.address === v.addr))
//           );
//           const expiry = new Date(v.base.config.expiration * 1e3);
//           const position: UnderwriterPosition = {
//             vaultID: v.addr,
//             underwritingTokenInfo: {
//               address: v.base.config.underwritingToken.address,
//               symbol: v.base.config.underwritingToken.symbol,
//               decimals: v.base.config.underwritingToken.decimals,
//             },
//             shares:
//               positionType === UnderwritingPositionType.Standard
//                 ? v.base.state.underwritingPosition.standard.balance! // eslint-disable-line
//                 : v.base.state.underwritingPosition.rollover.balance!, // eslint-disable-line
//             network: v.chain.name,
//             name: cmsVault.map((vaultData) => vaultData.name).getOrElse(String),
//             status: getVaultStatus(v, expiry),
//             expiry,
//             vault: v,
//           };
//           return position;
//         })
//     )
//     .getOrElse(Array);
// }

export function usePortfolioPageContext() {
  return useContext(PortfolioPageContext);
}

export const PortfolioPageContextProvider: React.FC<any> = ({ children }) => {
  const { address } = useAccount();
  const [value, setValue] = useState<Maybe<PortfolioPageContextValue>>(
    Maybe.none()
  );


  useEffect(() => {
    const cleanup = () => {
      setValue(Maybe.none());
    };

    if (!address) {
      setValue(Maybe.none());
    }

    // const policyholderPositions = onChainData
    //   .map((d) =>
    //     d.pools
    //       .filter((pool) =>
    //         validPositionBalance(pool.state.protectionPositionToken.balance)
    //       )
    //       .map((p) => {
    //         const cmsPool = cmsData.flatMap((data) =>
    //           Maybe.from(
    //             data.pools.find(
    //               (poolData) =>
    //                 poolData.vault.address === p.vault.addr &&
    //                 poolData.poolId === p.id
    //             )
    //           )
    //         );
    //         const expiry = new Date(p.vault.base.config.expiration * 1e3);
    //         const position: PolicyholderPosition = {
    //           vaultID: p.vault.addr,
    //           poolID: p.id,
    //           protectedTokenInfo: {
    //             symbol: p.config.remoteProtectedToken.symbol,
    //             decimals: p.config.remoteProtectedToken.decimals,
    //           },
    //           // eslint-disable-next-line
    //           protectedTokenAmount: p.state.protectionPositionToken.balance!,
    //           network: p.vault.chain.name,
    //           name: cmsPool.map((poolData) => poolData.name).getOrElse(String),
    //           status: getPoolStatus(p, expiry),
    //           expiry,
    //           pool: p,
    //         };
    //         return position;
    //       })
    //   )
    //   .getOrElse(Array);

    // const standardPositions = getUnderwriterPositions(
    //   onChainData,
    //   cmsData,
    //   UnderwritingPositionType.Standard
    // );

    // setValue(
    //   Maybe.some({
    //     policyholderPositions,
    //     underwriterPositions: {
    //       standardPositions,
    //       rolloverPositions,
    //     },
    //   })
    // );

    return cleanup;
  }, [address]);

  return (
    <PortfolioPageContext.Provider value={value}>
      {children}
    </PortfolioPageContext.Provider>
  );
};
