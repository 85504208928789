/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ToolTip } from "../ToolTip/ToolTip";
import { BLOCKED_ADDRESSES, VaultDetails } from "../../constants/constants";
import { TokenAmountInput } from "../OrderBox/TokenAmountInput";
import { OrderButton } from "../OrderBox/OrderButton";
// import { Switch } from '@chakra-ui/react';
import { Maybe } from "../../utils/maybe";
// import { Time } from '../../utils/time';
// import { FixedPointNumber } from '../../utils/fixedPoint';
import { checkZeroAmount } from "../../utils/helpers";
// import { Logger } from '../../utils/logger';
import { useBlockedUser } from "../../contexts/BlockedUserContext";
import { useAccount, useNetwork } from "wagmi";
import { useDeposit } from "../../actions/Deposit";
import { FixedPointNumber } from "../../utils/fixedPoint";
import { Logger } from "../../utils/logger";
import { activeVault } from "../../constants/Vault";
import { Address } from "@wagmi/core";
import { SUPPORTED_LOCAL_CHAINS } from "../../contexts";
import { NetworkGuard } from "./NetworkGuard";
import { useKarakBalances } from "../../hooks/userBalances";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-blue);
  border-radius: 5px;
  margin-top: 2rem;
  margin-bottom: 4.5rem;

  @media only screen and (max-width: 499px) {
    flex-direction: column-reverse;
  }
`;

const Text = styled.p`
  font-size: var(--h3-font-size);
  color: #8893AF;
  font-weight: 500;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

const ExpiryText = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  text-align: center;

  @media only screen and (max-width: 499px) {
    font-size: var(--p2-font-size);
  }
`;

const AmountText = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-primary-white);
  overflow: hidden;
  white-space: nowrap;
  max-width: 50%;
  text-overflow: ellipsis;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

const VFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HFlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  onConnect: () => void;
}


export const Deposit: React.FC<Props> = ({ onConnect }) => {
  const userBlocked = useBlockedUser();
  const account = useAccount();
  const vaultNotExpired = true;
  const showDepositRewards = true;
  const [amount, setAmount] = useState<Maybe<string>>(Maybe.none());
  const deposit = useDeposit(activeVault.address);
  const balances = useKarakBalances(account.address as Address).karakBalances;

  useEffect(() => {
    deposit.zip(amount).map(([_deposit, _amount]) => 
      _deposit.setDepositAmount(FixedPointNumber.fromDecimal(_amount, 18)) //hardcoded 18 because both Eth and StEth have 18 decimals
    );
  }, [amount, deposit]);

  const network = useNetwork();
  const correctNetwork: boolean = SUPPORTED_LOCAL_CHAINS.filter((c) => c.id === network.chain?.id).length > 0;
  if(!correctNetwork) {
    return(
      <NetworkGuard network={activeVault.network} />
    )
  }

  return (
    <>
      <Container>
        {vaultNotExpired ? (
          <>
            <TokenAmountInput
              actionType="Deposit"
              tokenOptions={["wstETH"]}
              amount={amount.string()}
              maxAmount={balances.wstETHBalance}
              onChange={(v) =>
                setAmount(v.trim() ? Maybe.from(v) : Maybe.none())
              }
            />
            <AmountBox>
              <VFlexBox>
                {showDepositRewards ? (
                  <HFlexBox>
                    <Text>
                      DEPOSIT REWARDS
                      <ToolTip tooltipText={VaultDetails.DREW} width="100%" />
                    </Text>
                    <AmountText>0.00 %</AmountText>
                  </HFlexBox>
                ) : (
                  <></>
                )}
              </VFlexBox>
            </AmountBox>
            <OrderButton
              actionType="Deposit"
              onConnect={onConnect}
              blockedUser={
                userBlocked.isBlocked ||
                BLOCKED_ADDRESSES.includes(account.address ?? "")
              }
              zeroAmount={checkZeroAmount(amount)}
              // insufficientFunds={Boolean(
              //   Number(amount.string()) > Number(maxAmount)
              // )}
              // vaultPaused={vault
              //   .map((v) => v.onchain.base.state.isPaused)
              //   .getOrElse(Boolean)}
              onClick={async () => {
                const _deposit = deposit.required("Deposit not ready");
                const res = await _deposit.perform();
                const txHash = res.required("Deposit not successful");
                Logger.info("Deposit Tx Hash: ", txHash);
                setAmount(Maybe.none());
              }}
            />
          </>
        ) : (
          <>
            <AmountBox style={{ marginBottom: "var(--section-bm)" }}>
              <ExpiryText>
                The vault has expired. Deposit is unavailable at this time.
              </ExpiryText>
            </AmountBox>
            <OrderButton disabled actionType="Deposit" />
          </>
        )}
      </Container>
    </>
  );
};
