import { QUEUE_POLL_WAIT_SECS } from "../constants/constants";
import { Config } from "../contexts";
import { Logger } from "./logger";

interface ClaimParamsResp {
  beaconStateRoot: string,
  beaconStateRootProof: string[],
  blockHeaderRoot: string,
  slashed: number,
  slashedProof: string[],
  slot: bigint,
  validatorIndex: number,
  validatorProof: string[],
  validatorRoot: string
}

const sleep = (ms: number) => new Promise<void>(resolve => setTimeout(resolve, ms));

export const getClaimData = async (validatorIndex: string, config: Config): Promise<ClaimParamsResp> => {
  const backendUrl = config.backendUrl;

  const slash_proof_req_json = await (await fetch(backendUrl + "slashing_proof_generation?" + new URLSearchParams({
    validator_index: validatorIndex,
  }))).json();
  if (slash_proof_req_json["status"] === "validator not slashed") {
    throw new Error("Validator is not slashed");
  }
  if (slash_proof_req_json["beaconStateRoot"] !== undefined) {
    return slash_proof_req_json as ClaimParamsResp;
  }
  await sleep(1000);
  while (true) {
    const claimResponse = await fetch(backendUrl + "claim_proof_status?" + new URLSearchParams({
      validator_index: validatorIndex
    }));
    const claimRespJson = await claimResponse.json();
    if (claimRespJson['status'] === "processing proof") {
      Logger.info("checked queue status");
    }
    else if (claimRespJson['status'] === "validator not in queue") {
      throw new Error("validator not in queue");
    } else {
      return claimRespJson as ClaimParamsResp;
    }
    await sleep(QUEUE_POLL_WAIT_SECS);
  }
};