import { useConfig, useHeartbeat } from "../contexts";
import { Action } from "../utils/action";
import { Maybe } from "../utils/maybe";
import { waitForTxToMine } from "../utils/transaction";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { Result } from "../utils/result";
import { useNotifications } from "../notifications";
import { Text } from "@chakra-ui/react";
import { Logger } from "../utils/logger";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { TxExplorerLink } from "../components/TxExplorerLink";
import { Tx, TxHash } from "../actions/shared";
import { getContract } from "@wagmi/core";
import { Address } from "viem";
import {
  vaultAbi as vaultContractAbi
} from "../constants/abis/Vault";
import { getConversionData } from "../utils/conversionParams";
import { getClaimData } from "../utils/claimParams";
import { getRootSignatureData } from "../utils/getRootSignature";

interface Claim extends Tx {
  setValidatorIndex(val: number): void;
}

const PROVIDER_NOT_LOADED_MESSAGE = "Provider not loaded in useClaim";
const SIGNER_NOT_LOADED_MESSAGE = "Signer not loaded in useClaim";

export function useClaim(vaultContractAddress: Address): Maybe<Claim> {
  let validatorIndex = 0;
  const account = useAccount();
  const publicClient = usePublicClient();
  const heartbeat = useHeartbeat();
  const walletClient = useWalletClient();
  const notifications = useNotifications();
  const claimAction = new Action<TxHash>();
  const config = useConfig();

  // ERC20#approve
  claimAction
    .addStep({
      async call() {
        const s = Maybe.from(walletClient.data).required(
          SIGNER_NOT_LOADED_MESSAGE
        );
        const vaultContract = getContract({
          abi: vaultContractAbi,
          address: vaultContractAddress,
          walletClient: s,
        });
        notifications.info(
            "Filing event in progress", "Please wait while your event is being processed"
        )
        const conversionData = await getConversionData(config);
        const slashingProofData = await getClaimData(validatorIndex.toString(), config);
        const rootSignatureData = await getRootSignatureData(slashingProofData.slot.toString(), config);
        const txHash = await vaultContract.write.claim([
            [
                slashingProofData.beaconStateRoot,
                slashingProofData.beaconStateRootProof,
                slashingProofData.blockHeaderRoot,
                slashingProofData.slashed,
                slashingProofData.slashedProof,
                slashingProofData.slot,
                slashingProofData.validatorIndex,
                slashingProofData.validatorProof,
                slashingProofData.validatorRoot,
                account.address,
                rootSignatureData.signature
            ],
            conversionData.conversionFactor,
            conversionData.conversionFactorExpiration,
            conversionData.signature
        ]);

        heartbeat.sendPulse(); // send a heartbeat pulse to refresh data after claim
        return txHash;
      },
      async waitForSatisfaction(txHash): Promise<void> {
        const p = Maybe.from(publicClient).required(
          PROVIDER_NOT_LOADED_MESSAGE
        );
        const txHashLink = makeTxExplorerLink(publicClient.chain, txHash);
        await waitForTxToMine(p, txHash);
        notifications.success(
            "Claim Successful",
          txHashLink
            .map((l) => (
              <TxExplorerLink explorerName={l.name} explorerUrl={l.url} />
            ))
            .getOrElse(() => <></>)
        );
      },
      async satisfied(): Promise<boolean> {
        // terminal step
        return false;
      },
    });

  return Maybe.some<Claim>({
    setValidatorIndex(_validatorIndex) {
      if (validatorIndex !== _validatorIndex) {
        validatorIndex = _validatorIndex;
      }
    },
    async perform(): Promise<Result<TxHash, Error>> {
      let res: Result<TxHash, Error> = Result.error(
        new Error("Claim had 0 actions")
      );

      while (!claimAction.completed) {
        res = await claimAction.step();
        if (res.isError) {
          Logger.error(
            "Transaction Failed",
            res.mapErr((e) => e).getOrElse(() => new Error())
          );
          notifications.error(
            "Claim Failed",
            <Text>Check console for error</Text>
          );
          return res;
        }
      }

      return res;
    },
  });
}
