import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Table, Td, Th, Tr, Box, Thead, Tbody, Button, Spinner } from '@chakra-ui/react';
import { PaginationHOC } from './PaginationHOC';
import { ToolTip } from '../ToolTip/ToolTip';
import { ProtectionDetails } from '../../constants/constants';
import { decimalFormat, mapRawAmountToUi } from '../../utils/helpers';
import { getTokenIcon } from '../../utils/token-icon';
import emptyTokenIcon from '../../assets/empty-token.png';
import { WithdrawBridgeMessage, finishUserWithdrawal } from '../../actions/offboarding-withdraw';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import { Logger } from '../../utils/logger';
import { useNotifications } from '../../notifications';

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const STr = styled(Tr)`
  background-color: var(--table-background-blue);
  height: 5rem;
`;

const STh = styled(Th)`
  font-weight: 700;
  font-size: 14px !important;
  color: var(--text-secondary-gray);
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 15px;
`;

const TokenSymbol = styled.span`
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary-white);
`;

const Image = styled.img`
  width: 25px;
  margin-right: 7px;
`;

const HFlexbox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface Props {
  items: WithdrawBridgeMessage[];
}

const tokenAddressToInfoMap: {[addr: string]: { decimals: number; symbol: string }} = {
  '0x45982AE5C1dd27d87c5b2D2aEfE756Ae27607FF3': { 
    decimals: 18,
    symbol: 'wstETH',
  },
  '0x0000000000000000000000000000000000000000': { 
    decimals: 18,
    symbol: 'ETH',
  }
};

export const WithdrawTable = PaginationHOC<Props>(({ items }) => {
  const [loading, setLoading] = useState(false);
  const [currentTxHash, setCurrentTxHash] = useState('');
  const { switchNetworkAsync } = useSwitchNetwork();
  const { chain } = useNetwork();
  const notifications = useNotifications();

  const finishWithdraw = useCallback(async (txHash: string): Promise<void> => {
    try {
      setLoading(true);
      await finishUserWithdrawal(txHash, switchNetworkAsync, chain?.id, notifications);
      setLoading(false);
      setCurrentTxHash('');
    } catch (e) {
      setLoading(false);
      setCurrentTxHash('');
      Logger.error('Error:', e);
    }
  }, [switchNetworkAsync, chain?.id, notifications]);

  return (
    <Box overflowX="auto">
      <STable variant="unstyled">
        <Thead>
          <Tr>
            <STh>
              ASSET
              <ToolTip tooltipText={ProtectionDetails.PA} />
            </STh>
            <STh textAlign="right">
              AMOUNT
              <ToolTip tooltipText={ProtectionDetails.NET} />
            </STh>
            <STh />
            <STh />
            <STh>
              STATUS
              <ToolTip tooltipText={ProtectionDetails.STATUS} />
            </STh>
          </Tr>
        </Thead>
        {items.filter((w) => (w.l1Token in tokenAddressToInfoMap && w.status !== 'completed')).map((withdrawal: WithdrawBridgeMessage, index) => (
          <Tbody key={index}>
            <STr>
              <STd>
                <HFlexbox>
                  <Image
                    src={getTokenIcon('karak', withdrawal.l1Token)}
                    alt=""
                    onError={(e) => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (e.target as any).src = emptyTokenIcon;
                    }}
                  />
                  <TokenSymbol>
                    {tokenAddressToInfoMap[withdrawal.l1Token].symbol}
                  </TokenSymbol>
                </HFlexbox>
              </STd>
              <STd textAlign="right">
                {decimalFormat(mapRawAmountToUi(
                  withdrawal.amount.toString(),
                  tokenAddressToInfoMap[withdrawal.l1Token].decimals
                ))}
              </STd>
              <STd />
              <STd />
              <STd>
                {withdrawal.status.toUpperCase()}
              </STd>
              <STd>
                <Button
                  isDisabled={withdrawal.status === 'pending'}
                  _hover={{
                    textDecoration: 'none',
                    filter: 'brightness(85%)',
                  }}
                  _active={{ color: 'transparent' }}
                  _focus={{ outline: 'none', boxShadow: 'none' }}
                  px="var(--button-px)"
                  py="var(--button-py)"
                  textColor="white"
                  background="var(--components-primary-blue)"
                  borderRadius="5px"
                  fontSize="var(--p2-font-size)"
                  onClick={() => {
                    setCurrentTxHash(withdrawal.transactionHash);
                    finishWithdraw(withdrawal.transactionHash);
                    withdrawal.status = "completed";
                  }}
                  width="8rem"
                >
                  {
                    currentTxHash === withdrawal.transactionHash && loading
                      ? <Spinner />
                      : 'Finish Withdraw'
                  }
                </Button>
              </STd>
            </STr>
          </Tbody>
        ))}
      </STable>
    </Box>
  );
});
