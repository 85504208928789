import { getPublicClient } from "@wagmi/core";
import { Address, getContract } from "viem";
import { vaultAbi } from "../constants/abis/Vault";
import { getConversionData } from "./conversionParams";
import { Config } from "../contexts";
import { PROVIDE_PROTECTION } from "../constants/BannerDetails";


export const getVaultLiquidity = async(vaultAddress: Address, config: Config) => {
    const publicClient = getPublicClient({chainId: 2511});
    // @ts-ignore
    const vaultContract= getContract({
        abi: vaultAbi,
        address: vaultAddress,
        publicClient
      });
    const underwriterLiquidity: bigint[] = await vaultContract.read.totalUnderWriterLiquidity() as bigint[];
    const underwriterEth = underwriterLiquidity[0];
    const underwriterToken = underwriterLiquidity[1];
    const usedUnderwriterLiquidity: bigint = await vaultContract.read.coverageInUse() as bigint;
    const conversionData = await getConversionData(config);
    const totalUnderwriterValueInEth = underwriterEth + (underwriterToken * BigInt(conversionData.conversionFactor) / BigInt(100000000));
    const leveragedTotalValue = totalUnderwriterValueInEth * BigInt(PROVIDE_PROTECTION.BANNER.LEVERAGE);
    const percentageUsed = totalUnderwriterValueInEth > 0 ? usedUnderwriterLiquidity * BigInt(100)/ leveragedTotalValue: 0;
    return {totalUnderwriterValueInEth, leveragedTotalValue, usedUnderwriterLiquidity, percentageUsed}
};