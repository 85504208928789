import numeral from "numeral";
import Decimal from "decimal.js";
import { BaseVault } from "@risk-harbor/subsea-sdk";
import { SECONDS_PER_YEAR } from "../constants/constants";
import { Maybe } from "./maybe";

export const getTokenSymbol = (value: string) => {
    return value.split("&")[1];
};

export const sigFigFormat = (amount: string) => {
    return numeral(Number(amount).toPrecision(3)).format(
        `0[.][00${"0".repeat(0)}]a`
    );
};

export const decimalFormat = (amount: string) => {
    return numeral(Number(amount).toFixed(2)).format(`0.00a`);
};

export const velodromeFormat = (amount: string) => {
    return Number(amount).toFixed(10);
};

export const tixFormat = (amount: number) => {
    return numeral(amount).format("0a");
};

export const mapRawAmountToUi = (rawAmount: string, decimals: number) => {
    return new Decimal(rawAmount).div(new Decimal(10).pow(decimals)).toString();
};

export const zeroAmountFallback = () => {
    return "0";
};

export const isZero = (x: bigint) => {
    return x === BigInt(0);
};

export const calculateYield = (vault: BaseVault): number => {
    const { allocationVector, premiums } = vault.state;
    const capacity = allocationVector[0];

    if (isZero(capacity) || isZero(premiums)) {
        return 0;
    }

    const start = Number(vault.config.start);
    const expiration = Number(vault.config.expiration);
    const yieldForTerm = new Decimal(capacity.toString()).div(
        (capacity - premiums).toString()
    );

    return yieldForTerm
        .sub(1)
        .mul(SECONDS_PER_YEAR)
        .div(expiration - start)
        .mul(100)
        .toNumber();
};

export const calculateLeverage = (vault: BaseVault): number => {
    const capacity = vault.state.allocationVector[0];
    const poolUtilSum = vault.state.allocationVector
        .slice(1)
        .reduce((a, b) => a + b, BigInt(0));

    const leverage =
        capacity > BigInt(0)
            ? new Decimal(poolUtilSum.toString()).div(capacity.toString())
            : new Decimal(0);

    return leverage.toNumber();
};

export const checkZeroAmount = (amount: Maybe<string>): boolean => {
    return (
        !amount.boolean() ||
        amount.map((v) => Number(v)).getOrElse(Number) === 0
    );
};

export const checkBlankValidatorIndex = (validatorIndex: Maybe<string>): boolean => {
    return (validatorIndex.string() === "");
}
