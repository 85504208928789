import React from "react";
import styled from "styled-components";
import {
  InputGroup,
  Button,
  NumberInput,
  NumberInputField,
  useRadioGroup,
  Flex,
  InputRightElement,
} from "@chakra-ui/react";
import { RadioCard } from "../RadioCard/RadioCard";
import { Maybe } from "../../utils/maybe";
import { ToolTip } from "../ToolTip/ToolTip";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: #D7D7D7;
  font-family: Helvetica Neue 500;
  font-weight: 500;
  margin-right: 0.5rem;
`;

const OnboardingText = styled.p`
  color: #c0c0c0;
  font-size: 0.9rem;
  margin-right: auto;
  margin-bottom: 1rem;
`;

const InputBox: typeof NumberInput = styled(NumberInput) <{ hasMultpleTokens: boolean }>`
  margin-bottom: 1rem;
  text-align: right;
  width: 100%;
  color: var(--text-primary-white);
  border-radius: 10px 0 0 10px !important;
  box-shadow: none !important;
`;

const InputBoxMultiple: typeof NumberInput = styled(NumberInput) <{ hasMultpleTokens: boolean }>`
  margin-bottom: 1rem;
  text-align: right;
  width: 100%;
  color: var(--text-primary-white);
  border-radius: 10px !important;
  box-shadow: none !important;
`;

const InputField: typeof NumberInputField = styled(NumberInputField)`
  width: 100%;
  height: 3.5rem !important;
  border: 1px solid rgba(251, 251, 255, 0.15) !important;
  text-align: left;
  font-size: 1.25rem !important;
  border-radius: 5px !important;
  box-shadow: none !important;
`;

const InputFieldMultiple: typeof NumberInputField = styled(NumberInputField)`
  width: 100%;
  text-align: right;
  padding-right: 10px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
`;

const IR = styled(InputRightElement)<{ wide: boolean, action: string }>`
  display: flex;
  column-gap: 1rem;
  height: 3.5rem !important;
  color: #8893AF;
  font-size: 1.25rem !important;
  font-weight: 600;
  width: ${(props) => (
    props.wide
      ? props.action.includes('Onboarding')
        ? `55%`
        : `45%`
      : props.action.includes('Onboarding')
        ? `45%`
        : `18%`
    )} !important;

  @media only screen and (max-width: 445px){
    width: ${(props) => (
      props.wide
        ? props.action.includes('Onboarding')
          ? `65%`
          : `55%`
        : props.action.includes('Onboarding')
          ? `50%`
          : `20%`
      )} !important;
  }
`;

const MaxButton = styled(Button)`
  width: 3.75rem !important;
  height: 50% !important;
  border-radius: 5px !important;
  background: var(--components-primary-blue) !important;
  color: var(--text-primary-white);
  font-size: var(--p2-font-size) !important;
  font-weight: 700 !important;
  padding: 0.3rem !important;

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    box-shadow: none !important;
  }

  @media only screen and (max-width: 499px) {
    &:hover {
      filter: brightness(100%);
    }
  }
`;

export type Token = 'ETH' | 'wstETH' | undefined;

interface Props {
  actionType: string;
  tokenOptions: string[];
  setTokenFunc?: React.Dispatch<React.SetStateAction<string>>;
  amount: string;
  maxAmount: string;
  onChange: (amount: string) => void;
  disabled?: boolean;
  maxDisabled?: boolean
}

export const TokenAmountInput: React.FC<Props> = ({
  actionType,
  tokenOptions,
  setTokenFunc,
  amount,
  maxAmount,
  onChange,
  disabled = false,
  maxDisabled = false
}) => {
  let amountText = "Amount";
  if (actionType === "Withdraw") {
    amountText = "Shares";
  }
  if (actionType === "OnboardingDeposit") {
    amountText = `Enter an amount to deposit.`;
  }
  if (actionType === 'Protect') {
    amountText = `Validator Protection Amount`;
  }

  const { getRadioProps } = useRadioGroup({
    name: "TokenCheckBox",
    defaultValue: "ETH",
    onChange: (val) => {
      Maybe.from(setTokenFunc).required("setToken function is undefined")(val as string);
    },
  });

  return (
    <Container>
      {actionType === ("OnboardingDeposit" || "OnboardingWithdraw") ? (
        <OnboardingText>{amountText}</OnboardingText>
      ) : (
        actionType === 'Protect' ? (
          <Flex alignItems={"center"} marginBottom={"0.5rem"}>
            <Text>{amountText}</Text>
            <ToolTip position tooltipText={"The amount of coverage you would like to have in the case of a slashing"}/*{NetworkDetails.GAB}*/ />
          </Flex>

        ) : (
          <Flex alignItems={"center"} marginBottom={"0.5rem"}>
            <Text>{amountText}</Text>
            <ToolTip position tooltipText={"The amount that you would like to deposit as an underwriter"}/*{NetworkDetails.GAB}*/ />
          </Flex>
        )
      )}
      <InputGroup>
        <Flex w="100%" justifyContent={"flex-start"} alignContent={"flex-start"} gap={tokenOptions.length === 1 ? 0 : 2}>
          {tokenOptions.length === 1 ?
            (
              <InputBox
                value={amount}
                clampValueOnBlur
                isDisabled={disabled}
                marginBottom={0}
                onChange={(v) => {
                  try {
                    if (Number.isNaN(Number(v))) {
                      return;
                    }
                  } catch {
                    return;
                  }

                  onChange(v);
                }}
              >
                <InputField placeholder="0" />
              </InputBox>) :
            (
              <InputBoxMultiple
                value={amount}
                clampValueOnBlur
                isDisabled={disabled}
                marginBottom={0}
                onChange={(v) => {
                  try {
                    if (Number.isNaN(Number(v))) {
                      return;
                    }
                  } catch {
                    return;
                  }

                  onChange(v);
                }}
              >
                <InputFieldMultiple placeholder="0" />
              </InputBoxMultiple>
            )
          }
          {tokenOptions.length === 1 ? (
            <IR wide={tokenOptions[0] === 'wstETH'} action={actionType}>
              <p>{tokenOptions[0]}</p>
              {!maxDisabled ?
                (<MaxButton
                  disabled={maxAmount === "" || disabled}
                  onClick={() => onChange(maxAmount)}
                >
                  MAX
                </MaxButton>
                )
                :
                (<></>)
              }
            </IR>
          ) : (
            <Flex borderRadius={100} border={"1px"} borderColor={"#281d45"}>
              {tokenOptions.map((value) => {
                const radio = getRadioProps({ value });
                return (
                  <RadioCard key={value} {...radio}>
                    {value}
                  </RadioCard>
                );
              })
              }
            </Flex>
          )}
        </Flex>
      </InputGroup>
    </Container>
  );
};
