import { Config } from "../contexts";

interface BeaconRootSignatureResp{
    "beaconStateRoot": string,
    "slot": number
    "signature": string
}

export const getRootSignatureData = async (slot: string, config: Config): Promise<BeaconRootSignatureResp> => {
    const backendUrl = config.backendUrl;
    const expirationResponse = await fetch(backendUrl + "beacon_root_sign_generation?" + new URLSearchParams({
        slot: slot
    }));
    const expirationResponseJson: BeaconRootSignatureResp = await expirationResponse.json();
    return expirationResponseJson;
  };
  