import React from 'react';
import styled from 'styled-components';
import { UnderwriteInfo } from './UnderwriteInfo';
import { VaultDetailsGrid } from './VaultDetailsGrid';
import { PoolDetailsGrid } from './PoolDetailsGrid';
import { ProtectionInfo } from './ProtectionInfo';

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--banner-gradient-purple);
  border-radius: var(--card-radius);
  width: 100%;
  margin-bottom: 3rem;
`;

interface Props {
  type: string;
}

export const Banner: React.FC<Props> = ({ type }) => {
  return (
    <>
      <FlexBox>
        <div>
          {type === 'protection' ? <ProtectionInfo /> : <UnderwriteInfo />}
        </div>
        {type === 'protection' ? <PoolDetailsGrid /> : <VaultDetailsGrid />}
      </FlexBox>
    </>
  );
};
