import { Config } from "../contexts";

interface ExpirationResp {
  conversionFactor: bigint;
  conversionFactorExpiration: bigint;
  signature: string;
}

export const getConversionData = async (config: Config): Promise<ExpirationResp> => {
  const backendUrl = config.backendUrl;
  const expirationResponse = await fetch(backendUrl + "conversion_factor_sign");
  const expirationResponseJson: ExpirationResp = await expirationResponse.json();
  return expirationResponseJson;
};
