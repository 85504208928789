import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
// import { Maybe } from '../../utils/maybe';
import { PortfolioPageContextProvider } from '../../contexts/Portfolio';
// import { decimalFormat, mapRawAmountToUi } from '../../utils/helpers';
// import moment from 'moment';
import shield from '../../assets/transparent-logos/shield.svg';
import coin from '../../assets/transparent-logos/coin.svg';
import { Address } from 'viem';
import { PolicyholderPositionItem, UnderwriterPositionItem } from '../../types/types';
import { PublicClient, useAccount, useNetwork, usePublicClient } from 'wagmi';
import { PositionTable } from '../Tables/PositionTable';
import { DECIMALS_18 } from '../../constants/constants';
import { VaultType, expiredVaults, activeVault } from '../../constants/Vault';
import { getShares } from '../../utils/getShares';
import {GetNetworkResult } from '@wagmi/core';
import { ProtectionTable } from '../Tables/ProtectionTable';
import { getAllProtectedValidators } from '../../utils/getAllProtectedValidators';
// import { ProtectionTable } from '../Tables/ProtectionTable';
// import { PositionTable } from '../Tables/PositionTable';

const CustomTab = styled(Tab)`
  font-size: var(--h2-font-size) !important;
  color: var(--text-primary-white) !important;
  text-align: center !important;
  font-weight: 600 !important;

  &:hover {
    filter: brightness(80%);
  }

  &:active {
    background-color: transparent !important;
    border-color: var(--components-primary-blue) !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

const Icon = styled.img`
  width: 1.625rem;
  margin-left: 0.5rem;
`;

const Text = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  margin-top: 2rem;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

const getUnderWritingPos = async (accountAddress: Address, allVaults: VaultType[], setUnderWritingTokenPosition: React.Dispatch<React.SetStateAction<UnderwriterPositionItem[]>>, publicClient: PublicClient) => {
  const underWritingPositions = await Promise.all(allVaults.map(async (vault: VaultType) => {
    const _shares = Number(await getShares(vault.address, accountAddress))/Number(DECIMALS_18)
    return { underwritingTokenInfo: vault.underWritingToken, shares: _shares.toString(), network: vault.network, name: vault.name, status: vault.status, expiry: vault.expiry, chainId: vault.chainId, address: vault.address as Address } as UnderwriterPositionItem
  }));
  setUnderWritingTokenPosition(underWritingPositions);
};

const getProtectionPosition = async (accountAddress: Address, publicClient: PublicClient, setProtectionPosition: React.Dispatch<React.SetStateAction<PolicyholderPositionItem[]>>, network: GetNetworkResult) => {
      const allProtectedValidators = await getAllProtectedValidators(accountAddress, activeVault.address);
      setProtectionPosition(
        allProtectedValidators.map((validator) => ({validatorIndex: validator.validatorIndex.toString(), protectedCoverageAmount: (Number(validator.coverage.coverage)/Number(DECIMALS_18)).toString(), network: activeVault.network, name: activeVault.name, expiry: activeVault.expiry, claimed: validator.coverage.status === 2, chainId: activeVault.chainId} as PolicyholderPositionItem))
      );
}

const PortfolioTabsInner: React.FC<any> = () => {

  const network = useNetwork();
  const publicClient = usePublicClient();
  const account = useAccount();

  const [underWritingPositions, setUnderWritingTokenPosition] = useState<UnderwriterPositionItem[]>([]);
  const [protectionPositions, setProtectionsPositions] = useState<PolicyholderPositionItem[]>([]);


  useEffect(() => {
    let allVaults = [activeVault, ...expiredVaults] as VaultType[];
    getUnderWritingPos(account.address as Address, allVaults, setUnderWritingTokenPosition, publicClient);
    getProtectionPosition(account.address as Address, publicClient, setProtectionsPositions, network);
  },[publicClient, underWritingPositions, protectionPositions, account.address, network]);


  return (
    <Tabs mb="5rem">
      <TabList borderColor="var(--background-blue)">
        <CustomTab mr="1rem" _selected={{ borderColor: 'var(--components-primary-blue)' }}>
          Protection <Icon src={shield} alt="shield" />
        </CustomTab>
        <CustomTab mr="1rem" _selected={{ borderColor: 'var(--components-primary-blue)' }}>
          Investing
          <Icon src={coin} alt="coin" />
        </CustomTab>
      </TabList>
      <TabPanels minH="100vh">
        <TabPanel padding="0 !important" marginTop={4}>
          {protectionPositions.length > 0?
          <ProtectionTable items={protectionPositions} />
          :
          <Text>You have no protected validators currently.</Text>
          }
        </TabPanel>
        <TabPanel padding="0 !important" marginTop={4}>
          {underWritingPositions.filter((vault) => parseFloat(vault.shares) > 0).length > 0?
          <PositionTable items={underWritingPositions} />
          :
          <Text>You have no positions currently.</Text>
          }
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export const PortfolioTabs: React.FC = () => {
  return (
    <PortfolioPageContextProvider>
      <PortfolioTabsInner />
    </PortfolioPageContextProvider>
  );
};
