import { ethers } from "ethers";
import { Text } from "@chakra-ui/react";
import { getEthersSigner } from "../utils/ethers";
import { Logger } from "../utils/logger";
import { createCrossChainMessenger, erc20Addrs } from "../utils/crossChainMessenger";
import { FixedPointNumber } from "../utils/fixedPoint";
import { Maybe } from "../utils/maybe";
import { MessageStatus, TokenBridgeMessage } from "@eth-optimism/sdk";
import { SwitchNetworkAsync } from "./shared";
import { Address } from "viem";
import { Notifications } from "../notifications";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { goerli } from "viem/chains";
import { ToastTitles } from "../constants/constants";
import { TxExplorerLink } from "../components/TxExplorerLink";
import { GOERLI_KARAK_RPC, GOERLI_RPC, GoerliL2 } from "../contexts";

export type WithdrawStatus = 'pending' | 'ready' | 'completed';
export type WithdrawBridgeMessage = TokenBridgeMessage & {
  status: WithdrawStatus;
}

export async function startWithdrawEthFromL2(
  amount: Maybe<string>,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
) {
  if (currentChainId !== 2511) { // if current connected network is not karak, trigger switch to karak
    await switchNetworkAsync?.(2511);
  }

  const l1Signer = new ethers.providers.JsonRpcProvider(GOERLI_RPC);
  const l2Signer = await getEthersSigner({ chainId: 2511});

  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }

  try {
    Logger.info('Begin withdraw eth from l2');
    const start = new Date();
    const crossChainMessenger = await createCrossChainMessenger(
      l1Signer,
      l2Signer
    );
    const response = await crossChainMessenger.withdrawETH(
      FixedPointNumber.fromDecimal(amount.string(), 18).toRawString()
    );
    Logger.info(`Transaction hash (on L2): ${response.hash}`);

    await response.wait(1);

    Logger.info(
      `startWithdrawETHFromL2 took ${(new Date().getTime() - start.getTime()) / 1000}
      seconds`
    );
    Logger.info(`Start withdraw eth tx hash: ${response.hash}`);
    const txHashLink = makeTxExplorerLink(GoerliL2, response.hash);
    notifications.success(
      ToastTitles.BridgeStartWithdrawEth.successMsg,
      txHashLink
        .map((l) => (
          <TxExplorerLink
            explorerName={l.name}
            explorerUrl={l.url}
          />
        ))
        .getOrElse(() => <></>)
    );
  } catch (e) {
    Logger.error('Error beginning withdraw ETH', e);
    notifications.error(
      ToastTitles.BridgeStartWithdrawEth.errorMsg,
      <Text>Check console for error</Text>
    );
  }
}

export async function startWithdrawERC20FromL2(
  amount: Maybe<string>,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
) {
  if (currentChainId !== 2511) { // if current connected network is not karak, trigger switch to karak
    await switchNetworkAsync?.(2511);
  }
  
  const l1Signer = new ethers.providers.JsonRpcProvider(GOERLI_RPC);
  const l2Signer = await getEthersSigner({ chainId: 2511});
  
  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }
  
  try {
    Logger.info('Begin withdraw erc20 from l2');
    const start = new Date();
    const crossChainMessenger = await createCrossChainMessenger(
      l1Signer,
      l2Signer
    );
    const response = await crossChainMessenger.withdrawERC20(
      erc20Addrs.l1,
      erc20Addrs.l2,
      FixedPointNumber.fromDecimal(amount.string(), 18).toRawString()
    );
    Logger.info(`Transaction hash (on L2): ${response.hash}`);

    await response.wait(1);
  
    Logger.info(
      `startWithdrawERC20FromL2 took ${(new Date().getTime() - start.getTime()) / 1000}
      seconds`
    );
    Logger.info(`Start bridging withdraw wstETH tx hash: ${response.hash}`);
    const txHashLink = makeTxExplorerLink(GoerliL2, response.hash);
    notifications.success(
      ToastTitles.BridgeStartWithdrawWstETH.successMsg,
      txHashLink
        .map((l) => (
          <TxExplorerLink
            explorerName={l.name}
            explorerUrl={l.url}
          />
        ))
        .getOrElse(() => <></>)
    );
  } catch (e) {
    Logger.error('Error starting bridge withdraw wstETH', e);
    notifications.error(
      ToastTitles.BridgeStartWithdrawWstETH.errorMsg,
      <Text>Check console for error</Text>
    );
  }
}

export async function getPendingWithdrawals(address: Address) {
  const l1Signer = new ethers.providers.JsonRpcProvider(GOERLI_RPC);
  const l2Signer = new ethers.providers.JsonRpcProvider(GOERLI_KARAK_RPC);
  const pendingWithdrawals: WithdrawBridgeMessage[] = [];

  const crossChainMessenger = await createCrossChainMessenger(
    l1Signer,
    l2Signer
  );

  const allWithdrawals = await crossChainMessenger.getWithdrawalsByAddress(address);

  for (const withdrawal of allWithdrawals) {
    const msgStatus = await crossChainMessenger.getMessageStatus(withdrawal.transactionHash);
    // Check that status is ready to prove since this means state root is
    // published from L2 to L1 and we can complete this withdraw from L2 to L1.
    if (msgStatus === MessageStatus.READY_TO_PROVE) {
      pendingWithdrawals.push({
        ...withdrawal,
        status: 'ready'
      })
    }
    if (msgStatus === MessageStatus.STATE_ROOT_NOT_PUBLISHED) {
      pendingWithdrawals.push({
        ...withdrawal,
        status: 'pending'
      })
    }
  }

  return pendingWithdrawals;
}

export async function finishUserWithdrawal(
  txHash: string,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
) {
  if (currentChainId !== 5) { // if current connected network is not goerli, trigger switch to goerli (in this case goerli is L1)
    await switchNetworkAsync?.(5);
  }
    
  const l1Signer = await getEthersSigner({ chainId: 5 });
  const l2Signer = new ethers.providers.JsonRpcProvider(GOERLI_KARAK_RPC);
    
  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }

  try {
    Logger.info('Completing withdrawal');

    const start = new Date();
    const crossChainMessenger = await createCrossChainMessenger(
      l1Signer,
      l2Signer
    );

    Logger.info('Prove message');
    await crossChainMessenger.proveMessage(txHash);

    Logger.info(
      'In the challenge period, waiting for status READY_FOR_RELAY'
    );
    Logger.info(
      `Time so far ${(new Date().getTime() - start.getTime()) / 1000} seconds`
    );
    await crossChainMessenger.waitForMessageStatus(txHash, MessageStatus.READY_FOR_RELAY);

    Logger.info('Ready for relay, finalizing message now');
    Logger.info(
      `Time so far ${(new Date().getTime() - start.getTime()) / 1000} seconds`
    );
    await crossChainMessenger.finalizeMessage(txHash);

    Logger.info('Waiting for status to change to RELAYED');
    Logger.info(
      `Time so far ${(new Date().getTime() - start.getTime()) / 1000} seconds`
    );
    await crossChainMessenger.waitForMessageStatus(txHash, MessageStatus.RELAYED);

    Logger.info(
      `finishUserWithdrawal took ${(new Date().getTime() - start.getTime()) / 1000}
      seconds`
    );
    Logger.info(`Finish bridging withdraw tx hash: ${txHash}`);
    const txHashLink = makeTxExplorerLink(goerli, txHash); // pass in chain dynamically later on
    notifications.success(
      ToastTitles.BridgeFinishWithdraw.successMsg,
      txHashLink
        .map((l) => (
          <TxExplorerLink
            explorerName={l.name}
            explorerUrl={l.url}
          />
        ))
        .getOrElse(() => <></>)
    );
  } catch (e) {
    Logger.error('Error finishing user withdrawal', e);
    notifications.error(
      ToastTitles.BridgeFinishWithdraw.errorMsg,
      <Text>Check console for error</Text>
    );
  }
}

