import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3rem;
`;

const LargeText = styled.h1`
  color: var(--text-primary-white);
  font-size: var(--h1-font-size);
  font-family: Helvetica Neue 700;
  font-weight: 700;

  @media only screen and (max-width: 325px) {
    font-size: var(--h2-font-size);
  }
`;

export const UnderwriteInfo: React.FC = () => {
  const vaultName = 'Staking Protection'

  return (
    <Container>
      <LargeText>{vaultName}</LargeText>
    </Container>
  );
};
