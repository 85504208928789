import React from 'react';
import styled from 'styled-components';
// import moment from 'moment';
import { ToolTip } from '../ToolTip/ToolTip';
import { PoolDetails } from '../../constants/constants';
import { PURCHASE_PROTECTION } from '../../constants/BannerDetails';
import { activeVault } from '../../constants/Vault';

const GridBox = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  margin-right: auto;
  grid-column-gap: 4rem;
  grid-row-gap: 2.5rem;

  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: normal;

  @media only screen and (max-width: 1473px) {
    margin-left: 0rem;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    justify-content: left;
    grid-column-gap: 15%;
    grid-row-gap: 2rem;
    align-items: normal;
  }

  @media only screen and (max-width: 700px) {
    grid-template-columns: auto auto;
  }

  @media only screen and (max-width: 499px) {
    grid-template-columns: auto;
  }
`;

const InfoContainer = styled.div`
  margin-right: auto;
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 500;
  opacity: 0.65;
`;

const SmallText = styled.p`
  color: var(--text-primary-white);
  font-size: var(--p-font-size);
  font-weight: 700;
`;

export const PoolDetailsGrid: React.FC = () => {
  const protectedActionText = 'PROTECTED ACTION';
  const underlyingTokenText = 'UNDERLYING TOKEN';
  const underwrtTokenText = 'UNDERWRITING TOKEN';
  const defaultRtText = 'DEFAULT RATIO';
  const payoutRtText = 'PAYOUT RATIO';
  const expDateText = 'EXPIRATION DATE';

  return (
    <GridBox>
      <InfoContainer>
        <Text>
          {protectedActionText}
          <ToolTip tooltipText={PoolDetails.PA} />
        </Text>
        <SmallText>
          {PURCHASE_PROTECTION.BANNER.PROTECTED_ACTION}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {underlyingTokenText}
          <ToolTip tooltipText={PoolDetails.ULT} />
        </Text>
        <SmallText>
          ETH
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {underwrtTokenText}
          <ToolTip tooltipText={PoolDetails.UWT} />
        </Text>
        <SmallText>
          {PURCHASE_PROTECTION.BANNER.UNDERWRITING_TOKEN}
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {defaultRtText}
          <ToolTip tooltipText={PoolDetails.DR} />
        </Text>
        <SmallText>
          ETH
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {payoutRtText}
          <ToolTip tooltipText={PoolDetails.PR} />
        </Text>
        <SmallText>
          1 Protected :{' '}
          1{' '}
          Underwriting
        </SmallText>
      </InfoContainer>
      <InfoContainer>
        <Text>
          {expDateText}
          <ToolTip tooltipText={PoolDetails.EXP} />
        </Text>
        <SmallText>
          {activeVault.expiry}
        </SmallText>
      </InfoContainer>
    </GridBox>
  );
};
