import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Spinner,
  Text,
} from '@chakra-ui/react';
import wstethLogo from '../../../assets/wstETH.svg';
import ethLogo from '../../../assets/network-logos/eth.svg';
import optLogo from '../../../assets/network-logos/optimism.svg';
import arbLogo from '../../../assets/network-logos/arbitrum.svg';
import { Maybe } from '../../../utils/maybe';
import { TokenAmountInput } from '../../OrderBox/TokenAmountInput';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { Logger } from '../../../utils/logger';
import { performDepositERC20, performDepositEth } from '../../../actions/onboarding-deposit';
import { useKarakBalances, useL1Balances } from '../../../hooks/userBalances';
import { decimalFormat, mapRawAmountToUi } from '../../../utils/helpers';
import { useNotifications } from '../../../notifications';
import { FixedPointNumber } from '../../../utils/fixedPoint';
import { ToolTip } from '../../ToolTip/ToolTip';
import { NetworkDetails, ToastTitles } from '../../../constants/constants';
import { getWalletClient } from '@wagmi/core';
import { GoerliL2 } from '../../../contexts';


const Header = styled(ModalHeader)`
  color: #c0c0c0;
  font-size: var(--h2-font-size) !important;
  font-weight: 500 !important;

  @media only screen and (max-width: 499px) {
    max-width: 225px;
  }
`;

const HeadingFlex = styled.div`
  display: flex;
  align-items: center;
`;

const TokenLogo = styled.img`
  width: 2rem;
  margin-right: 0.5rem;
`;

const ButtonFlexbox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubHeading = styled.p`
  color: #c0c0c0;
  font-size: 0.9rem;
  margin-right: auto;
  margin-bottom: 1rem;
`;

const Flexbox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const NetworkBox = styled.div<{ alreadyonnetwork?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid
    ${(props) => (props.alreadyonnetwork ? `var(--components-primary-blue)` : `transparent`)};
  width: 30%;
`;

const NetworkImg = styled.img`
  width: 3rem;
  margin-bottom: 0.5rem;
`;

const NetworkName = styled.p`
  color: #c0c0c0;
  font-size: 0.85rem;
  font-weight: 600;
`;

const InfoFlex = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 1.5rem;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #0F255B;
  padding: 0.75rem 1rem 0.75rem 1rem;
`;

const InfoText = styled.p`
  color: #c0c0c0;
  font-size: 0.9rem;
  font-weight: 600;
`;

interface Props {
  setPage: React.Dispatch<React.SetStateAction<boolean[]>>;
  asset: string;
}

export const OnboardingDeposit: React.FC<Props> = ({ setPage, asset }) => {
  const [amount, setAmount] = useState<Maybe<string>>(Maybe.none());
  const [loading, setLoading] = useState(false);
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();
  const notifications = useNotifications();
  const { ethBalance: l1EthBalance, wstETHBalance: l1wstETHBalance } = useL1Balances(address);
  const { karakBalances } = useKarakBalances(address);

  const backPage =
    asset === 'ETH'
      ? [false, false, true, false, false, false]
      : [false, false, false, true, false, false];
  const nextPage = useMemo(
    () =>
      asset === 'ETH'
        ? [false, false, false, false, true, false]
        : [false, false, false, false, false, true],
    [asset]
  );

  useEffect(() => {
    // Three cases here:
    // 1. Karak network in wallet so nothing will be prompted since already on network
    // 2. Karak network in wallet but you're on another network, so you will be prompted to switch
    // 3. Karak network not in wallet, so you will be prompted to add network and switch
    const addKarak = async () => {
      if (asset !== 'ETH') return; // only call this on Deposit ETH page of onboarding which is first step
      try {
        await switchNetworkAsync?.(2511);
      } catch (e) {
        if ((e as any).code === 4001) {
          try {
            const client = await getWalletClient();
            await client?.addChain({ chain: GoerliL2 });
          } catch (e) {
            Logger.error('Error adding Karak Testnet chain', e);
          }
        }
      }
    }
    addKarak();
  }, [address, switchNetworkAsync, asset]);

  const depositERC20 = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await performDepositERC20(amount, switchNetworkAsync, chain?.id, notifications);
      setLoading(false);
      setPage(nextPage);
    } catch (e) {
      setLoading(false);
      Logger.error('Error depositing ERC20 (wstETH) onto Karak network', e);
      notifications.error(
        ToastTitles.BridgeDepositWstETH.errorMsg,
        <Text>Check console for error</Text>
      );
    }
  }, [amount, nextPage, setPage, switchNetworkAsync, chain?.id, notifications]);

  const depositEth = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await performDepositEth(amount, switchNetworkAsync, chain?.id, notifications);
      setLoading(false);
      setPage(nextPage);
    } catch (e) {
      setLoading(false);
      Logger.error('Error depositing ETH onto Karak network:', e);
      notifications.error(
        ToastTitles.BridgeDepositEth.errorMsg,
        <Text>Check console for error</Text>
      );
    }
  }, [amount, nextPage, setPage, switchNetworkAsync, chain?.id, notifications]);

  return (
    <>
      <Header>
        <HeadingFlex>
          <TokenLogo src={asset === 'ETH' ? ethLogo : wstethLogo} alt={`${asset}-logo`} />
          Deposit {asset}
        </HeadingFlex>
      </Header>
      <ModalCloseButton
        style={{ color: '#d7d7d7', marginTop: 8 }}
        _focus={{ outline: 'none' }}
        _hover={{ filter: 'brightness(65%)' }}
      />
      <ModalBody mb={5}>
        <SubHeading>
          {asset === 'ETH' ? 'First, ': 'Next, '} choose a network to deposit {asset} from.
        </SubHeading>
        <Flexbox>
          <NetworkBox
            alreadyonnetwork={chain && chain.id === 2511}
            className="network-div"
            tabIndex={0}
          >
            <NetworkImg src={ethLogo} alt="eth" />
            <NetworkName>Ethereum</NetworkName>
          </NetworkBox>
          <NetworkBox className="network-div-disabled" tabIndex={0}>
            <NetworkImg src={optLogo} alt="optimism" />
            <NetworkName>Optimism</NetworkName>
          </NetworkBox>
          <NetworkBox className="network-div-disabled" tabIndex={0}>
            <NetworkImg src={arbLogo} alt="arbitrum" />
            <NetworkName>Arbitrum</NetworkName>
          </NetworkBox>
        </Flexbox>
        <TokenAmountInput
          actionType="OnboardingDeposit"
          tokenOptions={[asset]}
          amount={amount.string()}
          maxAmount={
            asset === 'ETH'
            ? FixedPointNumber.fromRaw(BigInt(l1EthBalance), 18).toString()
            : FixedPointNumber.fromRaw(BigInt(l1wstETHBalance), 18).toString()
          }
          onChange={(v) => setAmount(v.trim() ? Maybe.from(v) : Maybe.none())}
          disabled={false}
        />
        <InfoFlex>
          <InfoBox>
            <InfoText>
              Wallet Balance
              <ToolTip tooltipText={NetworkDetails.WB} />
            </InfoText>
            <InfoText style={{ marginLeft: 'auto' }}>
              {
                asset === 'ETH'
                ? decimalFormat(mapRawAmountToUi(l1EthBalance, 18))
                : decimalFormat(mapRawAmountToUi(l1wstETHBalance, 18))
              }
              {' '}{asset}
            </InfoText>
          </InfoBox>
          <InfoBox>
            <InfoText>
              Account Balance
              <ToolTip tooltipText={NetworkDetails.AB} />
            </InfoText>
            <InfoText style={{ marginLeft: 'auto' }}>
              {asset === 'ETH'
                ? decimalFormat(mapRawAmountToUi(karakBalances.ethBalance, 18))
                : decimalFormat(mapRawAmountToUi(karakBalances.wstETHBalance, 18))
              }
              {' '}{asset}
            </InfoText>
          </InfoBox>
          <InfoBox>
            <InfoText>
              Estimated Deposit Time
              <ToolTip tooltipText={NetworkDetails.EDT} />
            </InfoText>
            <InfoText style={{ marginLeft: 'auto' }}>5 min(s)</InfoText>
          </InfoBox>
        </InfoFlex>
        <ButtonFlexbox>
          <Button
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="#0F255B"
            borderRadius="5px"
            fontSize="var(--p-font-size)"
            onClick={() => {
              setPage(backPage);
            }}
            width="48%"
            marginTop="2.5rem"
          >
            Back
          </Button>
          <Button
            isDisabled={amount.string() === ''}
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="var(--components-primary-blue)"
            border="0.5px solid #7e9bd1"
            borderRadius="5px"
            fontSize="var(--p-font-size)"
            onClick={asset === 'ETH' ? depositEth : depositERC20}
            width="48%"
            marginTop="2.5rem"
          >
            {loading ? (
              <Spinner />
            ) : (
              <>
                Deposit
                {' '}{asset}
              </>
            )}
          </Button>
        </ButtonFlexbox>
      </ModalBody>
    </>
  );
};
