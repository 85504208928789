import { getPublicClient } from "@wagmi/core";
import { Address, getContract } from "viem";
import { vaultAbi } from "../constants/abis/Vault";


export const getShares = async(vaultAddress: Address, walletAddress: Address) => {
    const publicClient = getPublicClient({chainId: 2511});
    // @ts-ignore
    const vaultContract= getContract({
        abi: vaultAbi,
        address: vaultAddress,
        publicClient
      });
    const balance = await vaultContract.read.balanceOf([walletAddress]);
    return balance
};
