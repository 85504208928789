import React from 'react';
import styled from 'styled-components';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Link,
} from '@chakra-ui/react';

const Heading = styled.p`
  font-weight: bold;
  font-size: var(--p-font-size);
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
  margin-bottom: 15px;
`;

const MHeader = styled(ModalHeader)`
  font-size: var(--h3-font-size);
  color: var(--text-primary-white);

  @media only screen and (max-width: 796px) {
    font-size: var(--p-font-size) !important;
  }
`;

const Content = styled(ModalContent)`
  max-width: 400px !important;

  @media only screen and (max-width: 796px) {
    max-width: 300px !important;
  }
`;

export const OFACGuardModal: React.FC = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Heading onClick={onOpen}>Learn More</Heading>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="var(--overlay-modal-black)">
          <Content>
            <MHeader>SlashProof not available in your region</MHeader>
            <ModalCloseButton
              style={{ color: 'white', marginTop: 10 }}
              _focus={{ outline: 'none', boxShadow: 'none' }}
              _hover={{ filter: 'brightness(65%)' }}
            />
            <ModalBody>
              <Text>
                SlashProof is not currently available in the United States, and use
                of SlashProof is prohibited for{' '}
                <Link
                  // eslint-disable-next-line
                  href="https://home.treasury.gov/policy-issues/financial-sanctions/sanctions-programs-and-country-information"
                  isExternal
                  textDecoration="underline"
                  _focus={{
                    outline: 'none !important',
                    boxShadow: 'none !important',
                  }}
                >
                  OFAC-sanctioned countries
                </Link>
                , individuals, or entities. In the meantime, please join our
                Discord, follow us on Twitter, and read our docs to learn more.
              </Text>
            </ModalBody>
          </Content>
        </ModalOverlay>
      </Modal>
    </>
  );
};
