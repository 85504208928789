export enum TabStatus {
  PROTECT = 'protect',
  CLAIM = 'claim',
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}
  
export enum MenuStatus {
  BUY = 'buy',
  PROVIDE = 'provide',
  PORTFOLIO = 'portfolio',
}
  
export enum PoolDetails {
  PA = 'The action that is being protected.',
  ULT = 'The token that the claim token redeems into.',
  UWT = 'The token that the protection is underwritten in.',
  DR = 'The ratio for which a default event is considered.',
  PR = 'The ratio for which the user will be paid per each token covered.',
  EXP = 'The date when the pool expires.',
  OCA = 'Approve the token transfer with an off-chain signature which saves gas.',
}
  
export enum VaultDetails {
  UWA = 'The asset that underwrites the entire vault.',
  VC = 'The capacity of the vault.',
  RA = 'The willingness of a vault to underwrite additional risk.',
  NET = 'The blockchain on which the vault is hosted.',
  LVG = 'The ratio of underwriting capital to outstanding protection liability.',
  EXP = 'The date when the vault expires.',
  DREW = 'Deposit rewards per year.',
  TREW = 'Staking rewards per term (does not include premiums) split among all depositors.',
  // eslint-disable-next-line
  SUB = 'After the current vault expires, automatically rolls over deposited underwriting capital into the next live vault.',
  OCA = 'Approve the token transfer with an off-chain signature which saves gas.',
}
  
export enum ProtectionDetails {
  VI = "The validator index",
  PA = 'The amount protected.',
  NET = 'The blockchain on which the pool is hosted.',
  STATUS = 'The market status of the pool.',
  EXP = 'The date when the pool expires.',
  CLM = 'Button to claim coverage when validator is slashed'
}
  
export enum InvestingDetails {
  UND = 'The underwriting asset.',
  SHARES = 'The amount of shares you have within the deposited vault.',
  NET = 'The blockchain on which the vault is hosted.',
  NAME = 'The name of the vault.',
  STATUS = 'The market status of the vault.',
  EXP = 'The date when the vault expires.',
  WITHDRAW='Allows withrawl after vault is expired'
}
  
export enum PoolBreakdownDetails {
  PT = 'The token that is being protected.',
  RISK = 'The risk assessment score of the pool.',
  STATUS = 'The status of the pool.',
}

export enum NetworkDetails {
  WB = 'The current token balance in wallet on Goerli Testnet',
  AB = 'The current token balance in wallet on Karak L2',
  GAB = 'The current token balances in wallet on Karak L2',
  EDT = 'The estimated time to process deposit transaction on bridge',
  EWT = 'The estimated time to process withdraw transaction on bridge',
}
  
export const status = {
  0: 'Open',
  1: 'Paused',
  2: 'Hacked',
  3: 'Closed',
};
  
export enum VaultStatus {
  Open = 'Open',
  Paused = 'Paused',
  Expired = 'Expired',
}
  
export enum VaultRiskAversion {
  RiskOn = 'Risk-On',
  RiskOff = 'Risk-Off',
  Cautious = 'Cautious',
}
  
export enum PoolStatus {
  Open = 'Open',
  Paused = 'Paused',
  Hacked = 'Hacked',
  Closed = 'Closed',
}
  
export enum UnderwritingPositionType {
  Standard,
  Rollover,
}
  
export enum SubscriptionStatus {
  Subscribed = 'Subscribed',
  NotSubscribed = 'Not Subscribed',
}
  
export const GTM_ID = 'GTM-PP3X2BG';
  
export enum GOOGLE_EVENTS {
  NETWORK_CHANGE = 'network_change',
  WALLET_CONNECT = 'wallet_connect',
  BUY_PROTECTION_INITIATED = 'buy_protection_initiated',
  BUY_PROTECTION_SUCCESS = 'buy_protection_success',
  BUY_PROTECTION_FAILED = 'buy_protection_failed',
  PROVIDE_PROTECTION_STARTED = 'provide_protection_started',
  PROVIDE_PROTECTION_SUCCESS = 'provide_protection_success',
  PROVIDE_PROTECTION_FAILED = 'provide_protection_failed',
}
  
export enum FilterStatus {
  OPEN = 'open',
  PAUSED = 'paused',
  HACKED = 'hacked',
  EXPIRED = 'expired',
}
  
export const OrderBoxActions: { [action: string]: string } = {
  StartClaim: 'File Event',
  FinishClaim: 'Finish Event',
  Protect: 'Purchase Protection',
  Deposit: 'Deposit',
  Withdraw: 'Withdraw',
};
  
export const ToastTitles: {
  [action: string]: { successMsg: string; errorMsg: string };
} = {
  StartClaim: {
    successMsg: 'Started event successfully!',
    errorMsg: 'Error starting event',
  },
  CancelClaim: {
    successMsg: 'Canceled event successfully!',
    errorMsg: 'Error canceling event',
  },
  FinishClaim: {
    successMsg: 'Finished event successfully!',
    errorMsg: 'Error finishing event',
  },
  Protect: {
    successMsg: 'Purchased protection successfully!',
    errorMsg: 'Error purchasing protection',
  },
  Deposit: {
    successMsg: 'Deposited successfully!',
    errorMsg: 'Error depositing',
  },
  Withdraw: {
    successMsg: 'Withdrew shares successfully!',
    errorMsg: 'Error withdrawing',
  },
  Buyback: {
    successMsg: 'Sold back position successfully!',
    errorMsg: 'Error selling back position',
  },
  Subscribe: {
    successMsg: 'Subscribed to vault successfully!',
    errorMsg: 'Error subscribing',
  },
  Unsubscribe: {
    successMsg: 'Unsubscribed from vault sucessfully!',
    errorMsg: 'Error unsubscribing',
  },
  BridgeDepositEth: {
    successMsg: 'Successfully bridged ETH!',
    errorMsg: 'Error bridging ETH'
  },
  BridgeDepositWstETH: {
    successMsg: 'Successfully bridged wstETH!',
    errorMsg: 'Error bridging wstETH'
  },
  BridgeStartWithdrawEth: {
    successMsg: 'Successfully started ETH withdraw!',
    errorMsg: 'Error starting ETH withdraw',
  },
  BridgeStartWithdrawWstETH: {
    successMsg: 'Successfully started wstETH withdraw!',
    errorMsg: 'Error starting wstETH withdraw',
  },
  BridgeFinishWithdraw: {
    successMsg: 'Successfully finished withdraw!',
    errorMsg: 'Error finishing withdraw',
  },
  ReceiveTestnetTokens: {
    successMsg: 'Successfully received testnet tokens!',
    errorMsg: 'Error receiving testnet tokens',
  }
};
  
export const SECONDS_PER_YEAR = 31536000;
  
// 1 Ethereum
// 137 Polygon
// 42161 Arbitrum
// 43114 Avalanche
// 10 Optimism
// 250 Fantom
// 1313161554 Aurora
export const rewardsAddresses: { [chainID: number]: string } = {
  1: '0x56fc70e104aEa357F9E795c245be3689117D0030',
  137: '0xc0F5e29D35100fd7fE4e5AE573f441ae905F1505',
  42161: '0x7c1187AF4D6B23F7f7682799454168E24bC06EED',
  43114: '0x02529B996724d65cA2967C1AeD49cF35F6F5cC42',
  10: '0x56fc70e104aEa357F9E795c245be3689117D0030',
  250: '0x955237FACFA13e5b0Bd0fd4616e8c815498D21AF',
  1313161554: '0x70A0f9DF52FBB846F0AeFFdAf0D8746074D06392',
};
  
export const BLOCKED_COUNTRIES = [
  'United States',
  'Russia',
  'Iran',
  'North Korea',
  'Syria',
  'Afghanistan',
  'Cuba',
  'Belarus',
  'Burundi',
  'Central African Republic',
  'Democratic Republic of the Congo',
  'Iraq',
  'Libya',
  'Lebanon',
  'Somalia',
  'Venezuela',
  'Yemen',
  'Zimbabwe',
  'Sudan',
  'South Sudan',
  'Mali',
  'Myanmar',
  'Burma',
  'Nicaragua',
  'Ethiopia',
];
  
export const BLOCKED_ADDRESSES = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
];
  
export const LONG_TOKEN_NAMES: { [token: string]: string } = {
  'PT-GLP-28MAR2024': 'PT-GLP',
  'sAMM-wstETH/sUSD': 'sAMM',
};

export const DECIMALS_18 = BigInt(1000000000000000000);

export const QUEUE_POLL_WAIT_SECS = 5000;