import React from 'react';
import styled from 'styled-components';

const SDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #0F255B;
  border-radius: 5px;
`;

const Text = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  margin: auto;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

interface Props {
  network: string;
}

export const NetworkGuard: React.FC<Props> = ({ network }) => {
  return (
    <SDiv>
      <Text>Please switch to {network}</Text>
    </SDiv>
  );
};
