import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { BuyProtection } from '../../pages/BuyProtection';
import { ProvideProtection } from '../../pages/ProvideProtection';
import { Portfolio } from '../../pages/Portfolio';
import { Bridge } from '../../pages/Bridge';
import { DesktopMenu } from './DesktopMenu';
import { MobileMenu } from './MobileMenu';
import { NotFound } from '../../pages/NotFound';

const SubPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const Menu: React.FC = () => {
  return (
    <>
      <DesktopMenu />
      <MobileMenu />
      <SubPage>
          <Routes>
            <Route 
              path="/"
              element={<Navigate to="/buy-protection" replace />} 
            />
            <Route path="/buy-protection" element={<BuyProtection />} />
            <Route path="/provide-protection" element={<ProvideProtection />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/bridge" element={<Bridge />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
      </SubPage>
    </>
  );
};
