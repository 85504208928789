import React from 'react';
import { BlockchainProvider, ConfigProvider, HeartbeatProvider } from '../contexts';
import { BlockedUserProvider } from './BlockedUserContext';
import { OffboardingWithdrawalsProvider } from './OffboardingWithdrawals';

// This components sole responsibility is to wrap around all the contexts of this app in the correct order
interface Props {
  children: React.ReactNode;
}

export const ContextsWrapper: React.FC<Props> = ({ children }) => {
  return (
      <ConfigProvider>
        <HeartbeatProvider>
            <BlockchainProvider>
                  <BlockedUserProvider>
                    <OffboardingWithdrawalsProvider>
                      {children}
                    </OffboardingWithdrawalsProvider>
                  </BlockedUserProvider>
            </BlockchainProvider>
        </HeartbeatProvider>
      </ConfigProvider>
  );
};
