/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OrderButton } from "./OrderButton";
import { Maybe } from "../../utils/maybe";
// import { FixedPointNumber } from '../../utils/fixedPoint';
import {
  checkBlankValidatorIndex,
  checkZeroAmount,
  // decimalFormat,
  // mapRawAmountToUi,
} from "../../utils/helpers";
// import { Logger } from '../../utils/logger';
import { BLOCKED_ADDRESSES } from "../../constants/constants";
import { useBlockedUser } from "../../contexts/BlockedUserContext";
import { Address, useAccount, useNetwork } from "wagmi";
import { usePurchase } from "../../actions/Purchase";
import { FixedPointNumber } from "../../utils/fixedPoint";
import { Logger } from "../../utils/logger";
import { activeVault } from "../../constants/Vault"
import { PurchaseInput } from "./PurchaseInput";
import { getPurchaseData } from "../../utils/purchaseParams";
import { SUPPORTED_LOCAL_CHAINS, useConfig } from "../../contexts";
import { getConversionData } from "../../utils/conversionParams";
import { ToolTip } from "../ToolTip/ToolTip";
import { Flex, Skeleton, useRadioGroup } from "@chakra-ui/react";
import { NetworkGuard } from "./NetworkGuard";
import { useL1Balances } from "../../hooks/userBalances";
import { TokenAmountInput } from "./TokenAmountInput";
import { RadioCard } from "../RadioCard/RadioCard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5.5rem;

  @media only screen and (max-width: 499px) {
    flex-direction: column;
  }
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.25rem;
  background-color: #0F255B;
  border-radius: 5px;
  width: 48%;

  @media only screen and (max-width: 499px) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
  margin-bottom: 5px;
  white-space: nowrap;
  max-width: 95%;
  text-overflow: ellipsis;
`;

const TextLabel = styled.p`
  font-size: var(--p-font-size);
  color: #D7D7D7;
  font-family: Helvetica Neue 500;
  font-weight: 500;
`;


const Description = styled.p`
  font-size: var(--p-font-size);
  color: #8893AF;
  font-family: Helvetica Neue 500;
  font-weight: 500;
  text-align: center;
`;



interface Props {
  onConnect: () => void;
}

export const Protect: React.FC<Props> = ({ onConnect }) => {
  const userBlocked = useBlockedUser();
  const account = useAccount();
  const [validatorCoverage, setValidatorCoverage] = useState<Maybe<string>>(Maybe.none());
  const [purchasingToken, setPurchasingToken] = useState<string>("ETH");
  const purchase = usePurchase(activeVault.address);
  const [validatorIndex, setValidatorIndex] = useState<Maybe<string>>(Maybe.none());
  const config = useConfig();
  const [isPurchaseDataLoaded, setIsPurhcaseDataLoaded] = useState<boolean>(false);
  const tokenOptions = ["ETH", "wstETH"];
  const { getRadioProps } = useRadioGroup({
    name: "TokenCheckBox",
    defaultValue: "ETH",
    onChange: (val) => {
      Maybe.from(setPurchasingToken).required("setToken function is undefined")(val as string);
    },
  });

  const [minutes, setMinutes] = useState<number>(4);
  const [seconds, setSeconds] = useState<number>(59);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
  });

  const {ethBalance } = useL1Balances(account.address as Address);
  useEffect(() => {
    const _purchase = purchase.required("Purchase not loaded");
    _purchase.setPurchaseToken(purchasingToken);
  }, [account, purchasingToken, purchase]);

  useEffect(() => {
    purchase.zip(validatorCoverage).zip(validatorIndex).map(async ([[_purchase, _validatorCoverage], _validatorIndex]) => {
      setIsPurhcaseDataLoaded(false);
      _purchase.setCoverageAmount(FixedPointNumber.fromDecimal(_validatorCoverage, 18));
      _purchase.setPurchaseToken(purchasingToken);
      _purchase.setValidatorIndex(BigInt(_validatorIndex));
      if (validatorIndex.isSome() && validatorCoverage.isSome()) {
        setMinutes(4);
        setSeconds(59);
        const purchaseDataRes = await getPurchaseData(validatorIndex.required("validator index not loaded"), FixedPointNumber.fromDecimal(validatorCoverage.required("validator coverage not loaded"), 18).toRawString(), config)
        _purchase.setRiskScore(purchaseDataRes.riskScore);
        _purchase.setPremium(purchaseDataRes.premium);
        _purchase.setExpiration(purchaseDataRes.expiration);
        _purchase.setSignature(purchaseDataRes.signature);
        const conversionFactor = await getConversionData(config);
        _purchase.setConversionFactor(conversionFactor.conversionFactor);
        _purchase.setConversionFactorExpiration(conversionFactor.conversionFactorExpiration);
        _purchase.setConversionFactorSign(conversionFactor.signature);
        setIsPurhcaseDataLoaded(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatorCoverage, purchasingToken, validatorIndex, config]);

  const network = useNetwork();
  const correctNetwork: boolean = SUPPORTED_LOCAL_CHAINS.filter((c) => c.id === network.chain?.id).length > 0;
  if(!correctNetwork) {
    return(
      <NetworkGuard network={activeVault.network} />
    )
  }

  return (
    <>
      <Container>

        {/* <MultiTokenInput
          actionType={"protect"}
          asset={purchasingToken}
          tokens={['ETH', 'wstETH']}
          amount={validatorCoverage.string()}
          maxAmount={purchasingToken === "ETH"? ethBalance: wstETHBalance}
          onChange={(v) => }
          setAsset={setPurchasingToken}
          disabled={false}
          maxDisabled={true}
        /> */}
        <TokenAmountInput
              actionType="Protect"
              tokenOptions={["ETH"]}
              amount={validatorCoverage.string()}
              maxAmount={ethBalance}
              onChange={(v) =>
                setValidatorCoverage(v.trim() ? Maybe.from(v) : Maybe.none())
              }
              maxDisabled = {true}
            />
        <PurchaseInput validatorIndex={validatorIndex.string()} setValidatorIndex={setValidatorIndex} />
        <div>
          <Flex alignItems={"center"} alignContent={"center"} justifyItems={"center"}>
            <TextLabel>Pay Premium In</TextLabel>
            <ToolTip tooltipText="Select the currency you'd like to the pay the premium in"/>
          </Flex>
          <Flex marginTop={"0.5rem"} marginBottom={"1.5rem"} w="100%" justifyContent={"space-between"}>
            {tokenOptions.map((value) => {
              const radio = getRadioProps({ value });
                return (
                  <RadioCard key={value} {...radio}>
                    {value}
                  </RadioCard>
                );
              })
            }
          </Flex>
        </div>
        <SubContainer>
          <ItemBox>
            {validatorCoverage.isSome() && validatorIndex.isSome() ?
              (
                <Skeleton isLoaded={isPurchaseDataLoaded} w="6rem">
                  <Text>
                    {FixedPointNumber.fromRaw(purchase.required("Purchase not loaded").getPremium(), 18).toString()} {purchase.required("Purchase not loaded").getPurchaseToken()?.toString()}
                  </Text>
                </Skeleton>
              ) :
              (
                <Text>
                  0.00 {purchase.required("Purchase not loaded").getPurchaseToken()?.toString()}
                </Text>
              )
            }
            <Description>
              PREMIUM
              <ToolTip tooltipText="The amount of Premium to be paid out in tokens" />
            </Description>
          </ItemBox>
          <ItemBox>
            {validatorCoverage.isSome() && validatorIndex.isSome() ?
              (<Text>
                {minutes}:{seconds < 10 ? (<>0{seconds}</>) : (<>{seconds}</>)} min(s)
              </Text>)
              :
              <Text>0:00 min(s)</Text>
            }
            <Description>
              QUOTE EXPIRY
              <ToolTip tooltipText="Time left before signature expires." />
            </Description>
          </ItemBox>
        </SubContainer>

        <OrderButton
          actionType="Protect"
          onConnect={onConnect}
          blockedUser={
            userBlocked.isBlocked ||
            BLOCKED_ADDRESSES.includes(account.address ?? "")
          }
          zeroAmount={checkZeroAmount(validatorCoverage)}
          blankValidatorIndex={checkBlankValidatorIndex(validatorIndex)}
          // lowPremium={protect
          //   .quote()
          //   .zip(pool)
          //   .map(([_quote, _pool]) => {
          //     const premUndrRaw = _quote.output.premium;
          //     const undrToken = _pool.vault.base.config.underwritingToken;

          //     return (
          //       amount.string() &&
          //       Number(
          //         mapRawAmountToUi(premUndrRaw.toString(), undrToken.decimals)
          //       ) < 0.005
          //     );
          //   })
          //   .boolean()}
          // poolCapacityExceeded={Boolean(
          //   Number(amount.string()) > Number(maxProtectableAmount)
          // )}
          // insufficientFunds={protect
          //   .quote()
          //   .zip(pool)
          //   .map(([_quote, _pool]) => {
          //     const premUndrRaw = _quote.output.premium;
          //     const undrTokenBalRaw =
          //       _pool.vault.base.config.underwritingToken.balance;

          //     return Boolean(
          //       undrTokenBalRaw && premUndrRaw > BigInt(undrTokenBalRaw)
          //     );
          //   })
          //   .boolean()}
          // vaultPaused={pool
          //   .map((p) => p.vault.base.state.isPaused)
          //   .getOrElse(Boolean)}
          onClick={async () => {
            const _purchase = purchase.required("Purchase not loaded");
            try {
              const res = await _purchase.perform();
              const txHash = res.required("Purchase not successful");
              Logger.info(`TxHash: ${txHash}`);
            } catch {
              Logger.error("There was an error in purchase");
            }
            setValidatorCoverage(Maybe.none());
            setValidatorIndex(Maybe.none());
          }}
        />
      </Container>

    </>
  );
};
