import { Abi } from "viem";

export const vaultAbi: Abi = [
  {
    "inputs": [],
    "name": "AlreadyCoveredOrClaimed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "AmountIsZero",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "BlockTimeStampGreaterThanExpiration",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ClaimNotAllowed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ClaimNotSendByOwner",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ContractIsPaused",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ConversionSignVerificationFailed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ConversionSignatureExpired",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "CoverageGreaterThan32",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "FundOrWstEthDoesNotMatchParams",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidBeaconStateRootProof",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidInitialization",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidLightClientAddress",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "enum BeaconOracleHelper.ValidatorField",
        "name": "field",
        "type": "uint8"
      },
      {
        "internalType": "uint256",
        "name": "validatorIndex",
        "type": "uint256"
      }
    ],
    "name": "InvalidValidatorFieldProof",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "validatorIndex",
        "type": "uint256"
      }
    ],
    "name": "InvalidValidatorProof",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "MintingZeroSharesNotAllowed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotEnoughFundsForCoverage",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotEnoughShares",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotInitializing",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "OwnableInvalidOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "OwnableUnauthorizedAccount",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "PremiumLowerThanMinimumAllowed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "PurchaseSignIsExpired",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "PurchaseSignVerificationFailed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ReentrancyGuardReentrantCall",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "SignerAddressZero",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "StateRootVerificationFailed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ValidatorNotCovered",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ValidatorNotSlashed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "VaultExpired",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "WithdrawNotAllowed",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "validatorIndex",
        "type": "uint256"
      }
    ],
    "name": "BeaconOracleUpdate",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint64",
        "name": "version",
        "type": "uint64"
      }
    ],
    "name": "Initialized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "userAddress",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "bytes32",
            "name": "beaconBlockRoot",
            "type": "bytes32"
          },
          {
            "internalType": "bytes32[]",
            "name": "beaconStateRootProof",
            "type": "bytes32[]"
          },
          {
            "internalType": "bytes32",
            "name": "blockHeaderRoot",
            "type": "bytes32"
          },
          {
            "internalType": "uint64",
            "name": "slashed",
            "type": "uint64"
          },
          {
            "internalType": "bytes32[]",
            "name": "slashedProof",
            "type": "bytes32[]"
          },
          {
            "internalType": "uint256",
            "name": "slot",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "validatorIndex",
            "type": "uint256"
          },
          {
            "internalType": "bytes32[]",
            "name": "validatorProof",
            "type": "bytes32[]"
          },
          {
            "internalType": "bytes32",
            "name": "validatorRoot",
            "type": "bytes32"
          },
          {
            "internalType": "address payable",
            "name": "claimRetrievalAddress",
            "type": "address"
          },
          {
            "internalType": "bytes",
            "name": "signature",
            "type": "bytes"
          }
        ],
        "internalType": "struct ClaimParams",
        "name": "claimParams",
        "type": "tuple"
      },
      {
        "internalType": "uint256",
        "name": "convertToEthFactor",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "signatureExpiration",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "convertFactorSign",
        "type": "bytes"
      }
    ],
    "name": "claim",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "coverageInUse",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "userAddress",
        "type": "address"
      }
    ],
    "name": "coveredValidators",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "validatorIndex",
            "type": "uint256"
          },
          {
            "components": [
              {
                "internalType": "enum ValidatorStatus",
                "name": "status",
                "type": "uint8"
              },
              {
                "internalType": "address",
                "name": "owner",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "coverage",
                "type": "uint256"
              }
            ],
            "internalType": "struct ValidatorCoverage",
            "name": "coverage",
            "type": "tuple"
          }
        ],
        "internalType": "struct ValidatorsOfAddress[]",
        "name": "",
        "type": "tuple[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "convertToEthFactor",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "signatureExpiration",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "signature",
        "type": "bytes"
      }
    ],
    "name": "deposit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_validatorIndex",
        "type": "uint256"
      }
    ],
    "name": "getSlashed",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_messageSignerAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "underwritingTokenAddress",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_expiration",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "feeMaster",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "feeBasisPoints",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "minimumPremium",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "leverageBasisPoints",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "paused",
        "type": "bool"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "beaconStateRoot",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32[]",
        "name": "beaconStateRootProof",
        "type": "bytes32[]"
      },
      {
        "internalType": "bytes32",
        "name": "blockHeaderRoot",
        "type": "bytes32"
      },
      {
        "internalType": "uint64",
        "name": "slashed",
        "type": "uint64"
      },
      {
        "internalType": "bytes32[]",
        "name": "slashedProof",
        "type": "bytes32[]"
      },
      {
        "internalType": "uint256",
        "name": "slot",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "validatorIndex",
        "type": "uint256"
      },
      {
        "internalType": "bytes32[]",
        "name": "validatorProof",
        "type": "bytes32[]"
      },
      {
        "internalType": "bytes32",
        "name": "validatorRoot",
        "type": "bytes32"
      }
    ],
    "name": "isValidatorSlashed",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "leverage",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "slot",
            "type": "uint256"
          },
          {
            "internalType": "bytes32",
            "name": "beaconStateRoot",
            "type": "bytes32"
          },
          {
            "internalType": "bytes32[]",
            "name": "beaconStateRootProof",
            "type": "bytes32[]"
          }
        ],
        "internalType": "struct BeaconOracleHelper.BeaconStateRootProofInfo",
        "name": "_beaconStateRootProofInfo",
        "type": "tuple"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "validatorIndex",
            "type": "uint256"
          },
          {
            "internalType": "bytes32",
            "name": "validatorRoot",
            "type": "bytes32"
          },
          {
            "internalType": "bytes32[]",
            "name": "validatorProof",
            "type": "bytes32[]"
          }
        ],
        "internalType": "struct BeaconOracleHelper.ValidatorProofInfo",
        "name": "_validatorProofInfo",
        "type": "tuple"
      },
      {
        "internalType": "uint64",
        "name": "_validatorFieldLeaf",
        "type": "uint64"
      },
      {
        "internalType": "bytes32[]",
        "name": "_validatorFieldProof",
        "type": "bytes32[]"
      },
      {
        "internalType": "enum BeaconOracleHelper.ValidatorField",
        "name": "_field",
        "type": "uint8"
      },
      {
        "internalType": "bytes32",
        "name": "_blockHeaderRoot",
        "type": "bytes32"
      }
    ],
    "name": "proveValidatorField",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "riskScore",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "premium",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "validatorIndex",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_coverage",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "signatureExpiration",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "signature",
        "type": "bytes"
      },
      {
        "internalType": "uint256",
        "name": "convertToEthFactor",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "expirationOfConversionSign",
        "type": "uint256"
      },
      {
        "internalType": "bytes",
        "name": "convertFactorSign",
        "type": "bytes"
      }
    ],
    "name": "purchase",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bool",
        "name": "_pause",
        "type": "bool"
      }
    ],
    "name": "setPause",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "sharesToken",
    "outputs": [
      {
        "internalType": "contract ERC20Mintable",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalShares",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalUnderWriterLiquidity",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "underwritingToken",
    "outputs": [
      {
        "internalType": "contract IERC20",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "updatedMessageSignerAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "updatedFeeMaster",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "updatedFeeBasisPoints",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "updatedMinimumPremium",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "leverageBasisPoints",
        "type": "uint256"
      }
    ],
    "name": "updateConfig",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_expiration",
        "type": "uint256"
      }
    ],
    "name": "updateExpirationTime",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "validatorState",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "pubkeyHash",
        "type": "bytes32"
      },
      {
        "internalType": "bytes32",
        "name": "withdrawalCredentials",
        "type": "bytes32"
      },
      {
        "internalType": "uint64",
        "name": "effectiveBalance",
        "type": "uint64"
      },
      {
        "internalType": "bool",
        "name": "slashed",
        "type": "bool"
      },
      {
        "internalType": "uint64",
        "name": "activationEligibilityEpoch",
        "type": "uint64"
      },
      {
        "internalType": "uint64",
        "name": "activationEpoch",
        "type": "uint64"
      },
      {
        "internalType": "uint64",
        "name": "exitEpoch",
        "type": "uint64"
      },
      {
        "internalType": "uint64",
        "name": "withdrawableEpoch",
        "type": "uint64"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address payable",
        "name": "withdrawAddress",
        "type": "address"
      }
    ],
    "name": "withdraw",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address payable",
        "name": "withdrawAddress",
        "type": "address"
      }
    ],
    "name": "withdrawServiceFee",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];