import React from 'react';
import styled from 'styled-components';
import { Banner } from '../components/Banner/Banner';
import { OrderBox } from '../components/OrderBox';
import { InfoDesc } from '../components/PoolVaultInfo/InfoDesc';
import { useNetwork } from 'wagmi';
import { PURCHASE_PROTECTION } from '../constants/BannerDetails';

const HFlex = styled.div`
  display: flex;
  column-gap: 2rem !important;
  padding-right: 2rem;
  padding-left: 2rem;

  @media only screen and (max-width: 1149px) {
    flex-direction: column;
    column-gap: 0rem !important;
    padding: 0rem;
  }
`;

const VFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media only screen and (max-width: 1149px) {
    width: 100%;
  }
`;

export const BuyProtection: React.FC = () => {
  const network = useNetwork();
  const networkMismatch = false;

  return (
    <HFlex>
      <VFlex>
        <Banner type="protection" />
        <InfoDesc
          type="pool"
          description={`${PURCHASE_PROTECTION.LONG_FORM.GENERAL}`}
          risksDesc={`${PURCHASE_PROTECTION.LONG_FORM.POLICY_HOLDER_RISK}`}
        />
      </VFlex>
      <OrderBox
        type="pool"
        networkMismatch={networkMismatch}
        network={network.chain?.name ?? ''}
      />
    </HFlex>
  );
};
