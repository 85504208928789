import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Tabs, TabList, TabPanels, Tab, TabPanel, useDisclosure } from '@chakra-ui/react';
import withdrawlogo from '../assets/transparent-logos/withdraw.svg';
import { useAccount } from 'wagmi';
import { WithdrawTable } from '../components/Tables/WithdrawTable';
import { OnboardingModal } from '../components/Modals/OnboardingModal';
import { ConnectEmptyState } from './ConnectEmptyState';
import { useOffboardingWithdrawalsContext } from '../contexts/OffboardingWithdrawals';

const CustomTab = styled(Tab)`
  font-size: var(--h2-font-size) !important;
  color: var(--text-primary-white) !important;
  text-align: center !important;
  font-weight: 600 !important;

  &:hover {
    filter: brightness(80%);
  }

  &:active {
    background-color: transparent !important;
    border-color: var(--components-primary-blue) !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

const Icon = styled.img`
  width: 1.625rem;
  margin-left: 0.5rem;
`;

const Text = styled.p`
  font-size: var(--h3-font-size);
  color: var(--text-secondary-gray);
  font-weight: 600;
  margin-top: 2rem;

  @media only screen and (max-width: 499px) {
    font-size: var(--p-font-size);
  }
`;

export const Bridge: React.FC = () => {
  const { address } = useAccount();
  const {
    onOpen: onOnboardingOpen,
    isOpen: isOnboardingOpen,
    onClose: onOnboardingClose,
  } = useDisclosure();
  const offboardingWithdrawals = useOffboardingWithdrawalsContext();
  const pendingWithdrawals = useMemo(
    () => 
      offboardingWithdrawals.map((d) => d.pendingWithdrawals),
    [offboardingWithdrawals]
  );

  return (
    <>
      {address ? (
        <Tabs mb="5rem">
          <TabList borderColor="var(--background-blue)">
            <CustomTab  mr="1rem" _selected={{ borderColor: 'var(--components-primary-blue)' }}>
              Withdrawals
              <Icon src={withdrawlogo} alt="withdraw" />
            </CustomTab>
          </TabList>
  
          <TabPanels minH="100vh">
            <TabPanel padding="0 !important">
              {pendingWithdrawals.isNone()
                ?
                  <Text>Fetching any pending withdrawals...</Text>
                : <>
                    {pendingWithdrawals.getOrElse(Array).length === 0 ? (
                      <Text>You have no pending withdrawals to complete.</Text>
                    ) : (
                      <WithdrawTable items={pendingWithdrawals.getOrElse(Array)} name="bridge" />
                    )}
                  </>
              }
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <ConnectEmptyState onClick={onOnboardingOpen} />
      )}
      <OnboardingModal isOpen={isOnboardingOpen} onClose={onOnboardingClose} />
    </>
  );
};
