export const PROVIDE_PROTECTION = {
    NAME_OF_VAULT: "Staking Vault",
    BANNER: {
        UNDERWRITING_ASSET: "wstETH",
        VAULT_CAPACITY: 0,
        RISK_AVERSION: "Risk-On",
        NETWORK: "Karak TestNet",
        LEVERAGE: 5,
    },
    LONG_FORM: {
        GENERAL: "stETH is the token that represents participation in Ethereum's transaction validation process following its move to a proof-of-stake consensus protocol. When staking, users lock in, or “stake,” tokens on the blockchain in order to earn validator opportunities that secure the network in exchange for rewards. In return for staking ethereum on LIDO(lido.fi), users receive stETH in return. A user can exchange 1 stETH for 1ETH anytime. This encompasses a few types of risks, such as validator slashing, governance attacks, rug pulls, economic exploits, or anything else that prevents stETH from being redeemed for ETH. For staking and buying protection, we use the wrapped form of stETH which is wstETH. wstETH is Defi protocol friendly. You can wrap your stETH to wstETH using https://stake.lido.fi/wrap/. You can also unwrap your wstETH to get stETH back."
        ,
        UNDERWRITER_RISKS:"Underwriters in SlashProof underwrite a group of validators in the vault. Each vault has its own Risk Aversion parameter which governs how willing the vault is to enter leverage and take on additional risks. This pool’s risk aversion parameter is Risk-On, signaling that risk aversion is relatively low. Underwriters attempt to earn higher yields by underwriting a larger amount of protection while taking on greater risk." + 
            "Operating leverage signals the ratio of outstanding protection liability to underwriting capital. The higher the operating leverage ratio, the more levered the vault is. The more levered the vault is, the higher the yields and the higher the risk of the vault defaulting will be." + 
            "Risk is assessed using a combination of quantitative risk modeling, publicly available security data, systemic dependencies, and market conditions. The yield on each pool can provide an indication of how risky the validator is by comparing the yield to quasi-risk-free rates elsewhere in the DeFi ecosystem. This rate comparison, combined with an efficient market hypothesis allows an attempt to price expected losses under ideal conditions. Correlated risks are assessed using quantitative risk modeling and dependency graphs." + 
            "Note that additional validators can still be added to the vault."
    }
};

export const PURCHASE_PROTECTION = {
    NAME_OF_POOL: "wstETH/ETH",
    BANNER: {
        POOL: "TestNet Pool",
        PROTECTED_ACTION: "Validator Slashing",
        UNDERLYING_TOKEN: "wstETH",
        UNDERWRITING_TOKEN: "wstETH",
        PAYOUT_RATIO: 2,
    },
    LONG_FORM: {
        GENERAL: "stETH is the token that represents participation in Ethereum's transaction validation process following its move to a proof-of-stake consensus protocol. When staking, users lock in, or “stake,” tokens on the blockchain in order to earn validator opportunities that secure the network in exchange for rewards. In return for staking ethereum on LIDO(lido.fi), users receive stETH in return. A user can exchange 1 stETH for 1ETH anytime. This encompasses a few types of risks, such as validator slashing, governance attacks, rug pulls, economic exploits, or anything else that prevents stETH from being redeemed for ETH. For staking and buying protection, we use the wrapped form of stETH which is wstETH. wstETH is Defi protocol friendly. You can wrap your stETH to wstETH using https://stake.lido.fi/wrap/. You can also unwrap your wstETH to get stETH back.",
        POLICY_HOLDER_RISK: "If a hack occurs, the policyholder will be paid out in wstETH. So unless x loses its peg, the underwriting capital will maintain its value. If another validator in the vault suffers a default event before this validator does, then the vault may default and be unable to pay out the full value of the protection."
    }
}