import React from 'react';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import {
  Button,
  Menu as ChakraMenu,
  MenuList,
  MenuItem,
  MenuButton,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import styled from 'styled-components';

const MenuContainer = styled.div`
  display: none;
  @media (max-width: 499px) {
    display: inline;
    margin: 0 auto 2rem 0;
  }
`;

export const MobileMenu: React.FC = () => {
  const tabText1 = 'Buy Protection';
  const tabText2 = 'Provide Protection';
  const tabText3 = 'Portfolio';
  const tabText4 = 'Bridge';

  let name = '';
  const { pathname: menuPath } = useLocation();

  if (menuPath === '/buy-protection') {
    name = tabText1;
  } else if (menuPath === '/provide-protection') {
    name = tabText2;
  } else if (menuPath === 'Portfolio') {
    name = tabText3;
  } else {
    name = tabText4;
  }

  return (
    <MenuContainer>
      <ChakraMenu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon w={6} h={6} />}
          background="var(--components-primary-blue)"
          _hover={{ filter: 'brightness(85%)', textDecoration: 'none' }}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
            textColor: 'var(--text-primary-white)',
          }}
          _active={{
            backgroundColor: 'var(--components-primary-blue)',
            textColor: 'var(--text-primary-white)',
          }}
          color="var(--text-primary-white)"
          marginBottom="1rem"
        >
          {name}
        </MenuButton>
        <MenuList
          bg="#0F255B"
          border="transparent"
          boxShadow="0px 5px 30px 0px rgba(187, 187, 187, 0.4)"
        >
          <RouteLink to="/buy-protection">
            <MenuItem
              color="var(--text-primary-white)"
              bg="transparent"
              _hover={{ filter: 'brightness(75%)' }}
            >
              Buy Protection
            </MenuItem>
          </RouteLink>
          <RouteLink to="/provide-protection">
            <MenuItem
              color="var(--text-primary-white)"
              bg="transparent"
              _hover={{ filter: 'brightness(75%)' }}
            >
              Provide Protection
            </MenuItem>
          </RouteLink>
          <RouteLink to="/portfolio">
            <MenuItem
              color="var(--text-primary-white)"
              bg="transparent"
              _hover={{ filter: 'brightness(75%)' }}
            >
              Portfolio
            </MenuItem>
          </RouteLink>
          <RouteLink to="/bridge">
            <MenuItem
              color="var(--text-primary-white)"
              bg="transparent"
              _hover={{ filter: 'brightness(75%)' }}
            >
              Bridge
            </MenuItem>
          </RouteLink>
        </MenuList>
      </ChakraMenu>
    </MenuContainer>
  );
};
