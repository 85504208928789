import { useState } from "react";
import { useConfig, useHeartbeat } from "../contexts";
import { Action } from "../utils/action";
import { Maybe } from "../utils/maybe";
import { waitForTxToMine } from "../utils/transaction";
import { useAccount, usePublicClient, useWalletClient} from "wagmi";
import { FixedPointNumber } from "../utils/fixedPoint";
import { Result } from "../utils/result";
import { useNotifications } from "../notifications";
import { Text } from "@chakra-ui/react";
import { Logger } from "../utils/logger";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { TxExplorerLink } from "../components/TxExplorerLink";
import { Tx, TxHash } from "../actions/shared";
import { ToastTitles } from "../constants/constants";
import { getContract } from "@wagmi/core";
import { Address, Hash } from "viem";
import {
  vaultAbi as vaultContractAbi
} from "../constants/abis/Vault";
import { underWritingTokenAbi as underWritingERC20Abi } from "../constants/abis/UnderwritingToken";
import { getConversionData } from "../utils/conversionParams";
import { underwritingToken } from "../constants/UnderwritingToken";

export interface Purchase extends Tx {
  setCoverageAmount(val: FixedPointNumber): void;
  setPurchaseToken(val: String): void;
  setValidatorIndex(val: bigint): void;
  setRiskScore(val: bigint): void;
  setPremium(val: bigint): void;
  setExpiration(val: bigint): void;
  setSignature(val: string): void;
  setConversionFactor(val: bigint): void;
  setConversionFactorExpiration(val: bigint): void;
  setConversionFactorSign(val: string): void;
  getPremium(): bigint;
  getValidatorIndex(): bigint;
  getValidatorCoverage(): FixedPointNumber;
  getPurchaseToken(): String | undefined;
}

const PROVIDER_NOT_LOADED_MESSAGE = "Provider not loaded in usePurchase";
const SIGNER_NOT_LOADED_MESSAGE = "Signer not loaded in usePurchase";
const WALLET_NOT_LOADED_MESSAGE = "Wallet not loaded in usePurchase";
// const VALIDATOR_INDEX_NOT_LOADED_MESSAGE = "Validator index not loaded in usePurchase";
// const COVERAGE_AMOUNT_NOT_LOADED_MESSAGE = "Coverage amount not loaded in usePurchase";

export function usePurchase(
  vaultAddress: Address,
): Maybe<Purchase> {
  const [coverageAmount, setCoverageAmount] = useState<FixedPointNumber>(FixedPointNumber.zero);
  const [purchaseToken, setPurchaseToken] = useState<string>();
  const [validatorIndex, setValidatorIndex] = useState<bigint>(BigInt(0));
  const [riskScore, setRiskScore] = useState<bigint>(BigInt(0));
  const [premium, setPremium] = useState<bigint>(BigInt(0));
  const [expiration, setExpiration] = useState<bigint>(BigInt(0));
  const [signature, setSignature] = useState<string>("");
  const [conversionFactor, setConversionFactor] = useState<bigint>(BigInt(0));
  const [conversionFactorExpiration, setConversionFactorExpiration] = useState<bigint>(BigInt(0));
  const [conversionFactorSign, setConversionFactorSign] = useState<string>("");
  const account = useAccount();
  const publicClient = usePublicClient();
  const heartbeat = useHeartbeat();
  const walletClient = useWalletClient();
  const notifications = useNotifications();
  const purchaseAction = new Action<TxHash>();
  const config = useConfig();

  purchaseToken === "wstETH"
    ? purchaseAction
      .addStep({
        async call(): Promise<TxHash> {
          const s = Maybe.from(walletClient.data).required(
            SIGNER_NOT_LOADED_MESSAGE
          );
          const undrErc20 = getContract({
            abi: underWritingERC20Abi,
            address: underwritingToken.address,
            walletClient: s,
          });
          const txHash = await undrErc20.write.approve([
            vaultAddress,
            coverageAmount.toBigInt(),
          ]);

          return txHash;
        },
        async waitForSatisfaction(txHash: Hash): Promise<void> {
          const p = Maybe.from(publicClient).required(
            PROVIDER_NOT_LOADED_MESSAGE
          );
          const txHashLink = makeTxExplorerLink(publicClient.chain, txHash);
          await waitForTxToMine(p, txHash);

          notifications.success(
            "ERC20 Approval Successful",
            txHashLink
              .map((l) => (
                <TxExplorerLink explorerName={l.name} explorerUrl={l.url} />
              ))
              .getOrElse(() => <></>)
          );
        },
        async satisfied(): Promise<boolean> {
          const owner = Maybe.from(account.address).required(
            WALLET_NOT_LOADED_MESSAGE
          );
          const undrErc20 = getContract({
            abi: underWritingERC20Abi,
            address: underwritingToken.address,
          });
          const _allowance: bigint = (await undrErc20.read.allowance([
            owner,
            vaultAddress,
          ])) as bigint;
          return _allowance >= coverageAmount.toBigInt();
        },
      })
      .addStep({
        async call() {
          const s = Maybe.from(walletClient.data).required(
            SIGNER_NOT_LOADED_MESSAGE
          );
          const vaultContract = getContract({
            abi: vaultContractAbi,
            address: vaultAddress,
            walletClient: s,
          });
          const conversionFactorData = await getConversionData(config);
          const txHash = await vaultContract.write.purchase([
            riskScore,
            premium,
            BigInt(validatorIndex),
            coverageAmount.toBigInt(),
            expiration,
            signature,
            conversionFactorData.conversionFactor,
            conversionFactorData.conversionFactorExpiration,
            conversionFactorData.signature,
          ]);

          heartbeat.sendPulse(); // send a heartbeat pulse to refresh data after purchase
          return txHash;
        },
        async waitForSatisfaction(txHash): Promise<void> {
          const p = Maybe.from(publicClient).required(
            PROVIDER_NOT_LOADED_MESSAGE
          );
          const txHashLink = makeTxExplorerLink(publicClient.chain, txHash);
          await waitForTxToMine(p, txHash);
          notifications.success(
            ToastTitles.Protect.successMsg,
            txHashLink
              .map((l) => (
                <TxExplorerLink explorerName={l.name} explorerUrl={l.url} />
              ))
              .getOrElse(() => <></>)
          );
        },
        async satisfied(): Promise<boolean> {
          // terminal step
          return false;
        },
      })
    : purchaseAction.addStep({
      async call() {
        const s = Maybe.from(walletClient.data).required(
          SIGNER_NOT_LOADED_MESSAGE
        );
        const vaultContract = getContract({
          abi: vaultContractAbi,
          address: vaultAddress,
          walletClient: s,
        });
        const conversionFactorData = await getConversionData(config);
        try {
        await vaultContract.simulate.purchase([
          riskScore,
          premium,
          BigInt(validatorIndex),
          coverageAmount.toBigInt(),
          expiration,
          signature,
          conversionFactorData.conversionFactor,
          conversionFactorData.conversionFactorExpiration,
          conversionFactorData.signature,
        ]);
      }
      catch(err) {
        const error = err as any;
        console.log("here 2: ", error.cause.data.errorName);
        if (error.cause.data.errorName === "AlreadyCoveredOrClaimed") {
          throw new Error("Validator index is already taken");
        }
      }
        const txHash = await vaultContract.write.purchase(
          [
            riskScore,
            premium,
            validatorIndex,
            coverageAmount.toBigInt(),
            expiration,
            signature,
            conversionFactorData.conversionFactor,
            conversionFactorData.conversionFactorExpiration,
            conversionFactorData.signature,
          ],
          { value: premium }
        );

        heartbeat.sendPulse(); // send a heartbeat pulse to refresh data after purchase 
        return txHash;
      },
      async waitForSatisfaction(txHash): Promise<void> {
        const p = Maybe.from(publicClient).required(
          PROVIDER_NOT_LOADED_MESSAGE
        );
        const txHashLink = makeTxExplorerLink(publicClient.chain, txHash);
        await waitForTxToMine(p, txHash);
        notifications.success(
          ToastTitles.Protect.successMsg,
          txHashLink
            .map((l) => (
              <TxExplorerLink explorerName={l.name} explorerUrl={l.url} />
            ))
            .getOrElse(() => <></>)
        );
      },
      async satisfied(): Promise<boolean> { // terminal step
        return false;
      },
    });

  return Maybe.some<Purchase>({
    setCoverageAmount(_coverage: FixedPointNumber) {
      if (_coverage.toBigInt() !== coverageAmount.toBigInt()) {
        setCoverageAmount(_coverage);
      }
    },
    setPurchaseToken(token: string) {
      if (token !== purchaseToken) {
        setPurchaseToken(token);
      }
    },
    setValidatorIndex(_validatorIndex: bigint) {
      if (_validatorIndex !== validatorIndex) {
        setValidatorIndex(_validatorIndex);
      }
    },
    setRiskScore(_riskScore: bigint) {
      if (_riskScore !== riskScore) {
        setRiskScore(_riskScore);
      }
    },
    setPremium(_premium: bigint) {
      if (_premium !== premium) {
        setPremium(_premium);
      }
    },
    setExpiration(_expiration: bigint) {
      if (_expiration !== expiration) {
        setExpiration(_expiration);
      }
    },
    setSignature(_signature: string) {
      if (_signature !== signature) {
        setSignature(_signature);
      }
    },
    setConversionFactor(_conversionFactor: bigint) {
      if (_conversionFactor !== conversionFactor) {
        setConversionFactor(_conversionFactor);
      }
    },
    setConversionFactorExpiration(_conversionFactorExpiration: bigint) {
      if (_conversionFactorExpiration !== conversionFactorExpiration) {
        setConversionFactorExpiration(_conversionFactorExpiration);
      }
    },
    setConversionFactorSign(_conversionFactorSign) {
      if (_conversionFactorSign !== conversionFactorSign) {
        setConversionFactorSign(_conversionFactorSign);
      }
    },
    getPremium() {
      return premium;
    },
    getValidatorIndex() {
      return validatorIndex;
    },
    getValidatorCoverage() {
      return coverageAmount;
    },
    getPurchaseToken() {
      return purchaseToken;
    }
    ,
    async perform(): Promise<Result<TxHash, Error>> {
      let res: Result<TxHash, Error> = Result.error(
        new Error("Purchase had 0 actions")
      );

      while (!purchaseAction.completed) {
        res = await purchaseAction.step();
        if (res.isError) {
          Logger.error(
            "Transaction Failed",
            res.mapErr((e) => e).getOrElse(() => new Error())
          );
          notifications.error(
            ToastTitles.Protect.errorMsg,
            <Text>Check console for error</Text>
          );
          return res;
        }
      }

      return res;
    },
  });
}
