import React from 'react';
import styled from 'styled-components';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import arrow from '../../assets/arrow-right.svg';
import { useAccount, useDisconnect } from 'wagmi';
import { OnboardingModal } from './OnboardingModal';
import { decimalFormat, mapRawAmountToUi } from '../../utils/helpers';
import { useKarakBalances } from '../../hooks/userBalances';
import { ToolTip } from '../ToolTip/ToolTip';
import { NetworkDetails } from '../../constants/constants';

const Header = styled(ModalHeader)`
  color: #c0c0c0;
  font-size: var(--h2-font-size) !important;
  font-weight: 500 !important;

  @media only screen and (max-width: 499px) {
    max-width: 225px;
  }
`;

const Content = styled(ModalContent)`
  max-width: 350px !important;
  background: var(--onboarding-color) !important;

  @media only screen and (max-width: 499px) {
    max-width: 300px !important;
  }
`;

const Text = styled.p`
  color: #d7d7d7;
  font-weight: 600;
`;

const Arrow = styled.img`
  width: 1rem;
  align-items: center;
  margin-top: 0.1rem;
  margin-left: 0.5rem;
`;

const BalanceBox = styled.div`
  border-radius: 5px;
  background: #0F255B;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 2.5rem;
`;

const Flexbox = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AccountInfoModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const {
    onOpen: onOnboardingOpen,
    isOpen: isOnboardingOpen,
    onClose: onOnboardingClose,
  } = useDisclosure();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { karakBalances, showOnboarding } = useKarakBalances(address);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <Content>
          <Header>
            {`${address?.substring(0, 6)}...${address?.substring(36, 42)}`}
          </Header>
          <ModalCloseButton
            style={{ color: '#d7d7d7', marginTop: 8 }}
            _focus={{ outline: 'none' }}
            _hover={{ filter: 'brightness(65%)' }}
          />
          <ModalBody>
            <Text style={{ marginLeft: '0rem', marginBottom: '0.5rem' }}>
              Account Balance
              <ToolTip tooltipText={NetworkDetails.GAB} />
            </Text>
            <BalanceBox>
              <Flexbox>
                <Text>wstETH:</Text>
                <Text style={{ marginLeft: 'auto' }}>
                  {decimalFormat(mapRawAmountToUi(karakBalances.wstETHBalance, 6))}
                </Text>
              </Flexbox>
              <Flexbox>
                <Text>ETH:</Text>
                <Text style={{ marginLeft: 'auto' }}>
                  {decimalFormat(mapRawAmountToUi(karakBalances.ethBalance, 18))}
                </Text>
              </Flexbox>
            </BalanceBox>
            {showOnboarding && (
              <Button
                _hover={{
                  textDecoration: 'none',
                  filter: 'brightness(85%)',
                }}
                _active={{ color: 'transparent' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                px="var(--button-px)"
                py="var(--button-py)"
                textColor="white"
                background="var(--components-primary-blue)"
                borderRadius="5px"
                fontSize="var(--p-font-size)"
                width="100%"
                marginBottom="2.5rem"
                onClick={() => {
                  onClose();
                  onOnboardingOpen();
                }}
              >
                Continue onboarding
                <Arrow src={arrow} alt="arrow" />
              </Button>
            )}
            <Button
              _hover={{
                textDecoration: 'none',
                filter: 'brightness(85%)',
              }}
              _active={{ color: 'transparent' }}
              _focus={{ outline: 'none', boxShadow: 'none' }}
              px="var(--button-px)"
              py="var(--button-py)"
              textColor="white"
              background="#c92240"
              borderRadius="5px"
              fontSize="var(--p-font-size)"
              width="100%"
              marginBottom="2.5rem"
              onClick={() => {
                disconnect();
                onClose();
              }}
            >
              Disconnect
            </Button>
          </ModalBody>
        </Content>
      </Modal>
      <OnboardingModal
        isOpen={isOnboardingOpen}
        onClose={onOnboardingClose}
        continueOnboarding
      />
    </>
  );
};
