import { ethers } from "ethers";
import { Address } from "wagmi";
import { erc20ABI, erc20Addrs } from "./crossChainMessenger";
import { GOERLI_KARAK_RPC, GOERLI_RPC } from "../contexts";

export async function getUserKarakBalances(address: Address) {
  const l2Signer = new ethers.providers.JsonRpcProvider(GOERLI_KARAK_RPC).getSigner(address);
  const l2ERC20 = new ethers.Contract(erc20Addrs.l2, erc20ABI, l2Signer);
  
  const ethBalance = (await l2Signer.getBalance()).toString();
  const wstETHBalance = (await l2ERC20.balanceOf(address)).toString() as string;

  return { ethBalance, wstETHBalance };
}

export async function getUserL1Balances(address: Address) {
  const l1Signer = new ethers.providers.JsonRpcProvider(GOERLI_RPC).getSigner(address);
  const l1ERC20 = new ethers.Contract(erc20Addrs.l1, erc20ABI, l1Signer);
    
  const ethBalance = (await l1Signer.getBalance()).toString();
  const wstETHBalance = (await l1ERC20.balanceOf(address)).toString() as string;
  
  return { ethBalance, wstETHBalance };
}
