import { ethers } from "ethers";
import { VStack, Text } from "@chakra-ui/react";
import { getEthersSigner } from "../utils/ethers";
import { Logger } from "../utils/logger";
import { createCrossChainMessenger, erc20Addrs } from "../utils/crossChainMessenger";
import { FixedPointNumber } from "../utils/fixedPoint";
import { Maybe } from "../utils/maybe";
import { MessageStatus } from "@eth-optimism/sdk";
import { SwitchNetworkAsync } from "./shared";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { Notifications } from "../notifications";
import { TxExplorerLink } from "../components/TxExplorerLink";
import { ToastTitles } from "../constants/constants";
import { GOERLI_KARAK_RPC } from "../contexts";
import { goerli } from "viem/chains";

export async function performDepositEth(
  amount: Maybe<string>,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
): Promise<void> {
  if (currentChainId !== 5) { 
    await switchNetworkAsync?.(5);
  }

  const l1Signer = await getEthersSigner({ chainId: 5});
  const l2Signer = new ethers.providers.JsonRpcProvider(GOERLI_KARAK_RPC);

  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }

  Logger.info('Deposit ETH');

  const start = new Date();
  const crossChainMessenger = await createCrossChainMessenger(
    l1Signer,
    l2Signer
  );
  const response = await crossChainMessenger.depositETH(
    FixedPointNumber.fromDecimal(amount.string(), 18).toRawString(),
  );
  Logger.info(`Transaction hash (on L1): ${response.hash}`);

  notifications.info(
    'Processing transaction...',
    <VStack alignItems="flex-start">
      <Text>Please be patient as your transaction</Text>
      <Text mt="-0.85rem">may take some time to process.</Text>
    </VStack>
  );

  await response.wait(1);

  Logger.info('Waiting for status to change to RELAYED');
  Logger.info(
    `Time so far ${(new Date().getTime() - start.getTime()) / 1000} seconds`
  );
  await crossChainMessenger.waitForMessageStatus(
    response.hash,
    MessageStatus.RELAYED
  );
  Logger.info('After wait for message status relayed');

  Logger.info(
    `depositETH took ${(new Date().getTime() - start.getTime()) / 1000}
    seconds`
  );
  Logger.info(`Bridged eth tx hash: ${response.hash}`);
  const txHashLink = makeTxExplorerLink(goerli, response.hash); // pass in chain dynamically later on
  notifications.success(
    ToastTitles.BridgeDepositEth.successMsg,
    txHashLink
      .map((l) => (
        <TxExplorerLink
          explorerName={l.name}
          explorerUrl={l.url}
        />
      ))
      .getOrElse(() => <></>)
  );
}

export async function performDepositERC20(
  amount: Maybe<string>,
  switchNetworkAsync: SwitchNetworkAsync,
  currentChainId: number | undefined,
  notifications: Notifications,
): Promise<void> {
  if (currentChainId !== 5) { 
    await switchNetworkAsync?.(5);
  }

  const l1Signer = await getEthersSigner({ chainId: 5});
  const l2Signer = new ethers.providers.JsonRpcProvider(GOERLI_KARAK_RPC);
  
  if (!l1Signer || !l2Signer) {
    throw new Error('Data still loading - l1 or l2 signer still missing');
  }
  
  Logger.info('Deposit ERC20');

  const start = new Date();
  const crossChainMessenger = await createCrossChainMessenger(
    l1Signer,
    l2Signer
  );
  const allowanceResponse = await crossChainMessenger.approveERC20(
    erc20Addrs.l1,
    erc20Addrs.l2,
    FixedPointNumber.fromDecimal(amount.string(), 18).toRawString()
  );
  await allowanceResponse.wait(1);
  Logger.info(`Allowance given by tx ${allowanceResponse.hash}`);
  Logger.info(
    `Time so far ${(new Date().getTime() - start.getTime()) / 1000} seconds`
  );
  
  const response = await crossChainMessenger.depositERC20(
    erc20Addrs.l1,
    erc20Addrs.l2,
    FixedPointNumber.fromDecimal(amount.string(), 18).toRawString()
  );
  Logger.info(`Deposit transaction hash (on L1): ${response.hash}`);

  notifications.info(
    'Processing transaction...',
    <VStack alignItems="flex-start">
      <Text>Please be patient as your transaction</Text>
      <Text mt="-0.85rem">may take some time to process.</Text>
    </VStack>
  );
  
  await response.wait();
  Logger.info('Waiting for status to change to RELAYED');
  Logger.info(
    `Time so far ${(new Date().getTime() - start.getTime()) / 1000} seconds`
  );
  await crossChainMessenger.waitForMessageStatus(
    response.hash,
    MessageStatus.RELAYED
  );
  
  Logger.info(
    `depositERC20 took ${(new Date().getTime() - start.getTime()) / 1000}
    seconds`
  );
  Logger.info(`Bridged erc20 tx hash: ${response.hash}`);
  const txHashLink = makeTxExplorerLink(goerli, response.hash); // pass in chain dynamically later on
  notifications.success(
    ToastTitles.BridgeDepositWstETH.successMsg,
    txHashLink
      .map((l) => (
        <TxExplorerLink
          explorerName={l.name}
          explorerUrl={l.url}
        />
      ))
      .getOrElse(() => <></>)
  );
}
