import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Box,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { ButtonConnect } from './ButtonConnect';
import { Logo } from './Logo';
import { AccountBalanceModal } from '../Modals/AccountBalanceModal';

const Desktop = styled.div`
  display: flex;
  column-gap: 0.5rem;

  @media only screen and (max-width: 1196px) {
    display: none;
  }
`;

const Mobile = styled.div`
  display: none;

  @media only screen and (max-width: 1196px) {
    display: block;
  }
`;

const Content = styled(ModalContent)`
  max-width: 300px !important;
`;

const CloseButton = styled(ModalCloseButton)`
  color: white;

  &:hover {
    filter: brightness(65%);
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const Header: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (window.innerWidth > 1196) {
        onClose();
      }
    };
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, [onClose]);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={2}
      py={10}
      px={0}
      bg={['primary.500', 'primary.500', 'transparent', 'transparent']}
      color={['white', 'white', 'white', 'white']}
    >
      <Logo />
      <Desktop>
        <AccountBalanceModal />
        <ButtonConnect />
      </Desktop>
      <Mobile>
        <Box as="button" onClick={onOpen}>
          <HamburgerIcon w={6} h={6} />
        </Box>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bg="var(--chakra-colors-blackAlpha-700)" />
          <Content bg="var(--onboarding-color)">
            <CloseButton />
            <ModalBody
              display="flex"
              flexDirection="column"
              marginTop={3}
              marginBottom={5}
              alignItems="center"
              rowGap={3}
            >
              <ButtonConnect />
              <AccountBalanceModal />
            </ModalBody>
          </Content>
        </Modal>
      </Mobile>
    </Flex>
  );
};
