import styled from 'styled-components';
import { Table, Td, Th, Tr, Box, Thead, Tbody, Spinner, Button } from '@chakra-ui/react';
import { PolicyholderPositionItem } from '../../types/types';
import { PaginationHOC } from './PaginationHOC';
import { ToolTip } from '../ToolTip/ToolTip';
import { ProtectionDetails } from '../../constants/constants';
import { useState } from 'react';
import { useClaim } from '../../actions/Claim';
import { activeVault } from '../../constants/Vault';
import { Logger } from '../../utils/logger';
import { useNetwork, useSwitchNetwork } from 'wagmi';

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const STr = styled(Tr)`
  background-color: var(--table-background-blue);
  height: 5rem;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
`;

const STh = styled(Th)`
  font-weight: 700;
  font-size: 14px !important;
  color: var(--text-secondary-gray);
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 15px;
`;

const TokenSymbol = styled.span`
  font-weight: 600;
  font-size: 15px;
  color: var(--text-primary-white);
`;

// const Text = styled.p`
//   font-size: var(--h3-font-size);
//   color: var(--text-secondary-gray);
//   font-weight: 600;

//   @media only screen and (max-width: 499px) {
//     font-size: var(--p-font-size);
//   }
// `;

interface Props {
  items: PolicyholderPositionItem[];
}

export const ProtectionTable = PaginationHOC<Props>(({ items }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingClaimValidator, setLoadingClaimValidator] = useState<string>("");
  const claim = useClaim(activeVault.address);
  const network = useNetwork();
  const switchNetwork = useSwitchNetwork();

  return (
    <Box overflowX="auto">
      <STable variant="unstyled">
        <Thead>
          <Tr>
            <STh>
              Validator Index
              <ToolTip tooltipText={ProtectionDetails.VI} />
            </STh>
            <STh>
              PROTECTED
              <ToolTip tooltipText={ProtectionDetails.PA} />
            </STh>
            <STh />
            <STh>
              NETWORK
              <ToolTip tooltipText={ProtectionDetails.NET} />
            </STh>
            <STh />
            {/* <STh>
              STATUS
              <ToolTip tooltipText={ProtectionDetails.STATUS} />
            </STh> */}
            <STh />
            <STh>
              EXPIRY DATE
              <ToolTip tooltipText={ProtectionDetails.EXP} />
            </STh>
            <STh>
              CLAIM
              <ToolTip tooltipText={ProtectionDetails.CLM} />
            </STh>
            <STh />
          </Tr>
        </Thead>
        {items.map((position: PolicyholderPositionItem, index) => (
          <Tbody key={index}>
            <STr>
              <STd>
                <TokenSymbol>
                  {position.validatorIndex}
                </TokenSymbol>
              </STd>
              <STd>{position.protectedCoverageAmount}</STd>
              <STd />
              <STd>{position.network}</STd>
              <STd />
              <STd />
              <STd>{position.expiry}</STd>
              <STd>
                {position.claimed ?
                  (
                    <Button borderWidth={1} textColor="white"
                      isDisabled={true}
                      background="var(--components-primary-blue)"
                      fontSize="var(--p2-font-size)" borderColor={"var(--border-blue)"}
                    >Filed Event</Button>
                  )
                  : (
                    <Button borderWidth={1} textColor="white"
                      background="var(--components-primary-blue)"
                      fontSize="var(--p2-font-size)" borderColor={"var(--border-blue)"}
                      onClick={async () => {
                        if (network.chain?.id !== position.chainId) {
                          switchNetwork.switchNetworkAsync?.(position.chainId);
                        }
                        if (!isLoading) {
                          setIsLoading(true);
                          setLoadingClaimValidator(position.validatorIndex);
                          const _claim = claim.required("File event not loaded");
                          _claim.setValidatorIndex(parseInt(position.validatorIndex));
                          try {
                            const res = await _claim.perform();
                            const txHash = res.required("File event not successful")
                            Logger.info("File event Tx Hash: ", txHash);
                          } catch { }
                          position.claimed=true;
                          setIsLoading(false);
                        }
                      }
                      }
                    >{isLoading && position.validatorIndex === loadingClaimValidator ? (<Spinner />) : (<>File Event</>)}</Button>
                  )
                }
              </STd>
              {/* TODO(zak): for later, when we re-enable buyback */}
              {/* <STd borderRight="1px solid var(--border-blue)">
                <BuyBackModal
                  poolName={position.name}
                  pool={position.pool}
                  poolNetwork={position.network}
                />
              </STd> */}
            </STr>
          </Tbody>
        ))}
      </STable>
    </Box>
  );
});
