const Networks: { [network: string]: string } = {
    Ethereum: "ethereum",
    Avalanche: "avalanchec",
    Optimism: "optimism",
    Arbitrum: "arbitrum",
};

// TODO(zak): Support chainID, token address
export function getTokenIcon(network: string, tokenAddress: string): string {
    // eslint-disable-next-line
    return `https://raw.githubusercontent.com/Risk-Harbor/assets/master/blockchains/${Networks[network]}/assets/${tokenAddress}/logo.png`;
}
