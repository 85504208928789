import React, { useEffect, useState } from 'react';
import { Progress } from '@chakra-ui/react';
import styled from 'styled-components';
import { PURCHASE_PROTECTION } from '../../constants/BannerDetails';
import { getVaultLiquidity } from '../../utils/vaultLiquidity';
import { activeVault } from '../../constants/Vault';
import { Address } from 'wagmi';
import { useConfig, useHeartbeat } from '../../contexts';
import { DECIMALS_18 } from '../../constants/constants';
// import { Maybe } from '../../utils/maybe';
// import {
//   decimalFormat,
//   mapRawAmountToUi,
// } from '../../utils/helpers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  row-gap: 1.5rem;
  width: 100%;

  @media only screen and (max-width: 1045px) {
    flex-direction: column;
    row-gap: 1.5rem;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1473px) {
    margin-right: 7rem;
  }

  @media only screen and (max-width: 1045px) {
    margin-right: auto;
    align-self: left;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ProgressBar = styled(Progress)`
  width: 400px;

  // @media only screen and (max-width: 1473px) {
  //   width: 65%;
  // }

  @media only screen and (max-width: 499px) {
    width: 100%;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: baseline;

  @media only screen and (max-width: 440px) {
    flex-direction: column;
  }
`;

const LargeText = styled.h1`
  color: var(--text-primary-white);
  font-size: var(--h1-font-size);
  font-family: Helvetica Neue 600;
  font-weight: 600;
  margin-right: 10px;

  @media only screen and (max-width: 375px) {
    font-size: var(--h2-font-size);
  }
`;

const Text = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--h2-font-size);

  @media only screen and (max-width: 375px) {
    font-size: var(--p-font-size);
  }
`;

const SmallText = styled.p`
  color: var(--text-secondary-gray);
  font-size: var(--p-font-size);
  font-weight: 600;
`;

export const ProtectionInfo: React.FC = () => {

  const [totalUnderwriterLiquidity, setTotalUnderwriterLiquidity] = useState<bigint>(BigInt(0));
  const [usedUnderwriterLiquidity, setUsedUnderwriterLiquidity] = useState<bigint>(BigInt(0));
  const [percentageUsed, setPercentageUsed] = useState<number>(0);
  const liquidityLeft: bigint = totalUnderwriterLiquidity - usedUnderwriterLiquidity;
  const heartbeat = useHeartbeat();
  const config = useConfig();
  useEffect(()=> {
    const updateVaultLiquidity = async () => {
      const resp = await getVaultLiquidity(activeVault.address as Address, config); 
      setTotalUnderwriterLiquidity(resp.leveragedTotalValue);
      setUsedUnderwriterLiquidity(resp.usedUnderwriterLiquidity);
      setPercentageUsed(Number(resp.percentageUsed))
    };
    updateVaultLiquidity();
  }, [totalUnderwriterLiquidity, usedUnderwriterLiquidity, heartbeat, config]);

  return (
    <Container>
      <SubContainer>
        <NameContainer style={{ flexDirection: 'row' }}>
          <LargeText>
            {PURCHASE_PROTECTION.NAME_OF_POOL}
          </LargeText>
          <Text>
            ETH
          </Text>
        </NameContainer>
        <SmallText>
          {PURCHASE_PROTECTION.BANNER.POOL}
        </SmallText>
      </SubContainer>
      <ProgressContainer>
        <ProgressBar
          size="xs"
          transition="all 0s"
          borderRadius="20px"
          value={Number(percentageUsed)}
          isAnimated
          mb={1}
        />
        <SmallText>
        {`${Number(liquidityLeft)/Number(DECIMALS_18)} ETH remaining`}
        </SmallText>
      </ProgressContainer>
    </Container>
  );
};
