import { getPublicClient } from "@wagmi/core";
import { Address, getContract } from "viem";
import { vaultAbi } from "../constants/abis/Vault";

interface CoveredValidators {
    validatorIndex: bigint;
    coverage: {
        status: number,
        coverage: bigint,
        owner: Address
    }
}

export const getAllProtectedValidators = async(accountAddress: Address, vaultAddress: Address) => {
    const publicClient = getPublicClient({
        chainId: 2511 
    })
    // @ts-ignore
    const vaultContract= getContract({
        abi: vaultAbi,
        address: vaultAddress,
        publicClient
      });
    const allValidators = await vaultContract.read.coveredValidators([accountAddress]);
    return allValidators as CoveredValidators[];
};
