import { WagmiConfig, createConfig } from 'wagmi'
import { InjectedConnector, configureChains } from '@wagmi/core';
import { alchemyProvider } from '@wagmi/core/providers/alchemy';
import { infuraProvider } from '@wagmi/core/providers/infura';
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc';
import { publicProvider } from '@wagmi/core/providers/public';
import { CoinbaseWalletConnector } from '@wagmi/core/connectors/coinbaseWallet';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { Buffer } from 'buffer';
import { Chain, foundry, goerli } from 'viem/chains';
import {
  REACT_APP_ALCHEMY_API_KEY,
  REACT_APP_INFURA_API_KEY,
  REACT_APP_WALLET_CONNECT_PROJECT_ID,
  REACT_APP_TENDERLY_RPC_URL,
  getBasedOnEnv,
} from '../utils/env';

if (!window.Buffer) {
  window.Buffer = Buffer;
}

export const GOERLI_RPC = 'https://omniscient-tame-valley.ethereum-goerli.quiknode.pro/e7dcaf3f52ab1f1bc2abdd9505ce800bb6679ef1/';
export const GOERLI_KARAK_RPC = 'https://goerli.node1.karak.network';

export const GoerliL2: Chain = {
  id: 2511,
  name: 'KarakTestnet',
  network: 'karak-testnet',
  nativeCurrency: {
    name: 'Karak Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://goerli.node1.karak.network'],
    },
    public: {
      http: [],
      webSocket: undefined
    }
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://goerli.scan.karak.network',
    }
  }
};

export const KarakDevnet: Chain = {
  id: 901,
  name: 'KarakDevnet',
  network: 'karak-devnet',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['http://localhost:9545'],
    },
    public: {
      http: ['http://localhost:9545'],
      webSocket: undefined
    }
  }
};

export const KarakMainet: Chain = {
  id: 900,
  name: 'KarakMainet',
  network: 'karak-mainet',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['http://localhost:8545'],
    },
    public: {
      http: ['http://localhost:8545'],
      webSocket: undefined
    }
  }
};

// export const SUPPORTED_CHAINS = [foundry, goerli, GoerliL2];

export const SUPPORTED_LOCAL_CHAINS =  getBasedOnEnv({
  production: [GoerliL2],
  staging: [GoerliL2],
  development: [foundry, GoerliL2, KarakDevnet],
});

export const SUPPORTED_REMOTE_CHAINS =  getBasedOnEnv({
  production: [goerli],
  staging: [goerli],
  development: [foundry, goerli, KarakMainet],
});


export const { chains, publicClient, webSocketPublicClient } = configureChains(
  SUPPORTED_LOCAL_CHAINS.concat(SUPPORTED_REMOTE_CHAINS),
  [
    alchemyProvider({ apiKey: REACT_APP_ALCHEMY_API_KEY }),
    infuraProvider({ apiKey: REACT_APP_INFURA_API_KEY }),
    publicProvider(),
    jsonRpcProvider({
      rpc: (c) => {
        return { http: c.rpcUrls.default.http[0] };
      },
    }),
    jsonRpcProvider({
      rpc: () => {
        return {
          http: REACT_APP_TENDERLY_RPC_URL,
        };
      },
    })
  ],
)
 
const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        shimDisconnect: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'SlashProof',
        
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: REACT_APP_WALLET_CONNECT_PROJECT_ID,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

interface Props {
  children: React.ReactNode;
}
  
export function BlockchainProvider({ children }: Props) {
  return <WagmiConfig config={config}>{children}</WagmiConfig>;
}
