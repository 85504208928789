import { Box, UseRadioProps, useRadio } from "@chakra-ui/react";

interface Props extends UseRadioProps {
  children: React.ReactNode;
}

export const RadioCard: React.FC<any> = (props: Props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" width="48%">
      <input {...input} />
      <Box
        {...checkbox}
        display="flex"
        cursor="pointer"
        boxShadow="md"
        borderRadius={"5px"}
        border={"1px solid rgba(251, 251, 255, 0.15)"}
        color={"#8893AF"}
        _checked={{
          borderColor: "var(--components-primary-blue)",
          textColor: "var(--components-primary-blue) !important",
        }}
        _focus={{
        }}
        height="4rem"
        fontSize="1.25rem"
        fontWeight="600"
      >
        <p style={{ margin: 'auto' }}>{props.children}</p>
      </Box>
    </Box>
  );
};
