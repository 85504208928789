import React from 'react';
import styled from 'styled-components';
import { Banner } from '../components/Banner/Banner';
import { OrderBox } from '../components/OrderBox';
import { InfoDesc } from '../components/PoolVaultInfo/InfoDesc';
import { useNetwork } from 'wagmi';
import { PROVIDE_PROTECTION } from '../constants/BannerDetails';

const HFlex = styled.div`
  display: flex;
  column-gap: 2rem !important;
  padding-right: 2rem;
  padding-left: 2rem;

  @media only screen and (max-width: 1149px) {
    flex-direction: column;
    column-gap: 0rem !important;
    padding: 0rem;
  }
`;

const VFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;

  @media only screen and (max-width: 1149px) {
    width: 100%;
  }
`;

export const ProvideProtection: React.FC = () => {
  const network = useNetwork();
  const networkMismatch = false;

  return (
    <HFlex>
      <VFlex>
        <Banner type="underwrite" />
        <InfoDesc
          type="vault"
          description={`${PROVIDE_PROTECTION.LONG_FORM.GENERAL}`}
          risksDesc={`${PROVIDE_PROTECTION.LONG_FORM.UNDERWRITER_RISKS}`}
        />
        {/* <PoolBreakDown
        pools={
          vaultPageData
            .map(({ vault }) => vault.onchain.chain.name)
            .string() === 'Avalanche'
            ? poolsInVault.getOrElse(Array).filter((p) => p.poolID !== 6)
            : poolsInVault.getOrElse(Array)
        }
        chain={vaultPageData.map(({ vault }) => vault.onchain.chain)}
      /> */}
      </VFlex>
      <OrderBox
        type="vault"
        networkMismatch={networkMismatch}
        network={network.chain?.name ?? ''}
      />
    </HFlex>
  );
};
