import './App.css';
import styled from 'styled-components';
import { Header } from './components/Header/Header';
import { Menu } from './components/Menu/Menu';
import { AppLoad } from './AppLoad';
import { useBlockedUser } from './contexts/BlockedUserContext';
import { OFACGuardModal } from './components/Modals/OFACGuardModal';

const Main = styled.div``;
const Container = styled.div``;
const Page = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  @media only screen and (max-width: 796px) {
    flex-direction: column;
  }
`;

const MergeMessage = styled.div`
  background: gray;
  color: var(--text-primary-white);
  font-weight: 600;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  column-gap: 0.3rem;

  @media only screen and (max-width: 515px) {
    display: block;
  }
`;

function App() {
  // const { appLoaded } = useLoadingContext();
  const appLoaded = true;
  const userBlocked = useBlockedUser();

  if (!appLoaded) {
    return <AppLoad />;
  }

  return (
    <Main className="main">
      {userBlocked.isBlocked && (
        <MergeMessage>
          SlashProof is not currently available in your region.
          <OFACGuardModal />
        </MergeMessage>
      )}
      <Container className="container">
        <Header />
        <Page>
          <Menu />
        </Page>
      </Container>
    </Main>
  );
};

export default App;
