import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import coin from '../../assets/coin-balance.svg';
import depositLogo from '../../assets/deposit.svg';
import withdrawLogo from '../../assets/withdraw.svg';
import arrow from '../../assets/arrow-right.svg';
import { useAccount } from 'wagmi';
import { OnboardingModal } from './OnboardingModal';
import { DepositWithdraw } from './DepositWithdraw/DepositWithdraw';
import { decimalFormat, mapRawAmountToUi } from '../../utils/helpers';
import { useKarakBalances } from '../../hooks/userBalances';
import { ToolTip } from '../ToolTip/ToolTip';
import { NetworkDetails } from '../../constants/constants';

const Header = styled(ModalHeader)`
  color: #c0c0c0;
  font-size: var(--h2-font-size) !important;
  font-weight: 500 !important;

  @media only screen and (max-width: 499px) {
    max-width: 225px;
  }
`;

const Content = styled(ModalContent)`
  max-width: 350px !important;
  background: var(--onboarding-color) !important;

  @media only screen and (max-width: 499px) {
    max-width: 300px !important;
  }
`;

const CoinImg = styled.img`
  width: 1.35rem;
  margin-right: 0.5rem;
  align-items: center;
`;

const Text = styled.p`
  color: #d7d7d7;
  font-weight: 600;
`;

const BalanceBox = styled.div`
  border-radius: 5px;
  background: #0F255B;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-bottom: 2rem;
`;

const Flexbox = styled.div`
  display: flex;
  align-items: center;
`;

const ActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 0.25rem;
  border-radius: 5px;
  background: var(--components-primary-blue);
  margin-bottom: 2rem;
  padding: 0.65rem;

  &:hover {
    filter: brightness(85%) !important;
    cursor: pointer !important;
  }
`;

const ActionHeading = styled.div`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: var(--p-font-size);
`;

const ActionImg = styled.img`
  width: 1.5rem;
`;

const Arrow = styled.img`
  width: 1rem;
  align-items: center;
  margin-left: 0.5rem;
`;

export const AccountBalanceModal: React.FC = () => {
  const [page, setPage] = useState([true, false]);
  const [action, setAction] = useState('');
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onOnboardingOpen,
    isOpen: isOnboardingOpen,
    onClose: onOnboardingClose,
  } = useDisclosure();
  const { isConnected, address } = useAccount();
  const { karakBalances, showOnboarding } = useKarakBalances(address);

  useMemo(() => {
    if (!isOpen) setPage([true, false]);
  }, [isOpen]);

  return (
    <>
      {isConnected && showOnboarding && (
        <>
          <Button
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="var(--components-primary-blue)"
            borderRadius="5px"
            fontSize="var(--p-font-size)"
            onClick={onOnboardingOpen}
          >
            Continue onboarding
            <Arrow src={arrow} alt="arrow" />
          </Button>
          <OnboardingModal
            isOpen={isOnboardingOpen}
            onClose={onOnboardingClose}
            continueOnboarding
          />
        </>
      )}
      {isConnected && !showOnboarding && (
        <>
          <Button
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="var(--components-primary-blue)"
            fontSize="0.9rem"
            onClick={onOpen}
          >
            <CoinImg src={coin} alt="coin" />
            Account Balance
          </Button>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <Content>
              {page[0] && (
                <>
                  <Header>Deposit or Withdraw Assets</Header>
                  <ModalCloseButton
                    style={{ color: '#d7d7d7', marginTop: 8 }}
                    _focus={{ outline: 'none' }}
                    _hover={{ filter: 'brightness(65%)' }}
                  />
                  <ModalBody>
                    <Text
                      style={{ marginLeft: '0rem', marginBottom: '0.5rem' }}
                    >
                      Account Balance
                      <ToolTip tooltipText={NetworkDetails.GAB} />
                    </Text>
                    <BalanceBox>
                      <Flexbox>
                        <Text>wstETH:</Text>
                        <Text style={{ marginLeft: 'auto' }}>
                          {decimalFormat(mapRawAmountToUi(karakBalances.wstETHBalance, 18))}
                        </Text>
                      </Flexbox>
                      <Flexbox>
                        <Text>ETH:</Text>
                        <Text style={{ marginLeft: 'auto' }}>
                          {decimalFormat(mapRawAmountToUi(karakBalances.ethBalance, 18))}
                        </Text>
                      </Flexbox>
                    </BalanceBox>
                    <ActionBox
                      onClick={() => {
                        setAction('deposit');
                        setPage([false, true]);
                      }}
                    >
                      <ActionImg src={depositLogo} alt="deposit" />
                      <ActionHeading>&nbsp;Deposit</ActionHeading>
                    </ActionBox>
                    <ActionBox
                      style={{ background: '#596282' }}
                      onClick={() => {
                        setAction('withdraw');
                        setPage([false, true]);
                      }}
                    >
                      <ActionImg src={withdrawLogo} alt="withdraw" />
                      <ActionHeading>&nbsp;Withdraw&nbsp;</ActionHeading>
                      <ActionHeading>(ETA ~3+ hours)</ActionHeading>
                    </ActionBox>
                  </ModalBody>
                </>
              )}
              {page[1] && <DepositWithdraw setPage={setPage} action={action} />}
            </Content>
          </Modal>
        </>
      )}
    </>
  );
};
