import React from 'react';
import styled from 'styled-components';
import {
  InputGroup,
  InputRightAddon,
  InputLeftElement,
  Button,
  NumberInput,
  NumberInputField,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ToolTip } from '../ToolTip/ToolTip';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const OnboardingText = styled.p`
  color: #c0c0c0;
  font-size: 0.9rem;
  margin-right: auto;
  margin-bottom: 1rem;
`;

const InputBox: typeof NumberInput = styled(NumberInput)`
  margin-bottom: 1rem;
  text-align: right;
  width: 100%;
  color: var(--text-primary-white);
  border-radius: 5px !important;
  box-shadow: none !important;
`;

const InputField: typeof NumberInputField = styled(NumberInputField)`
  width: 100%;
  text-align: right;
  border: 1px solid rgba(251, 251, 255, 0.15) !important;
  border-right: none !important;
  padding-right: 10px !important;
  border-radius: 5px 0 0 5px !important;
  box-shadow: none !important;
`;

const IR = styled(InputRightAddon)`
  background-color: #0F255B !important;
  border: 2px #281d45 !important;
  color: var(--text-primary-white);
  font-weight: 600;
`;

const MaxButton = styled(Button)`
  width: 3rem !important;
  height: 50% !important;
  border-radius: 5px !important;
  background: var(--components-primary-blue) !important;
  color: var(--text-primary-white);
  font-size: var(--p2-font-size) !important;
  padding: 0.3rem !important;

  &:hover {
    filter: brightness(80%);
  }

  &:focus {
    box-shadow: none !important;
  }

  @media only screen and (max-width: 499px) {
    &:hover {
      filter: brightness(100%);
    }
  }
`;

const Text = styled.p`
  font-size: var(--p-font-size);
  color: var(--text-primary-white);
  font-weight: 600;
  margin-right: 0.5rem;
`;

interface Props {
  actionType: string;
  tokens: string[];
  asset: string;
  amount: string;
  maxAmount: string;
  onChange: (amount: string) => void;
  setAsset: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  maxDisabled?: boolean
}

export const MultiTokenInput: React.FC<Props> = ({
  actionType,
  tokens,
  asset,
  amount,
  maxAmount,
  onChange,
  setAsset,
  disabled = false,
  maxDisabled = false
}) => {
  return (
    <Container>
      {actionType === 'protect'?
      (<Flex alignContent={"center"} alignItems={"center"} marginBottom={1}>
        <Text>
          Validator Protection Amount
        </Text>
        <ToolTip position tooltipText={"The amount of coverage you would like to have in the case of a slashing"}/*{NetworkDetails.GAB}*/ />
        </Flex>
      )
      : (
      <OnboardingText>
        Enter an amount to {actionType}.
      </OnboardingText>)
    }
      <InputGroup position="relative" zIndex={'dropdown'}>
        <InputLeftElement width="3rem !important" marginLeft="8px">
          {!maxDisabled?(
          <MaxButton
            disabled={maxAmount === '' || disabled}
            onClick={() => onChange(maxAmount)}
          >
            MAX
          </MaxButton>
          ):
          (<></>)
          }
        </InputLeftElement>

        <InputBox
          value={amount}
          clampValueOnBlur
          isDisabled={disabled}
          onChange={(v) => {
            try {
              if (Number.isNaN(Number(v))) {
                return;
              }
            } catch {
              return;
            }

            onChange(v);
          }}
        >
          <InputField placeholder="0" />
        </InputBox>
        <IR>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            bg='transparent'
            _hover={{ bg: 'transparent' }}
            _active={{ bg: 'transparent' }}
            padding={0}
          >
            {asset}
          </MenuButton>
          <MenuList
            minW="0"
            w={'80px'}
            bg="blue.900"
            border="transparent"
          >
            {tokens.map((token) => (
              <MenuItem
                onClick={() => {
                  setAsset(token);
                }}
                bg={'transparent'} 
                _hover={{ filter: 'brightness(65%)' }}
                key={token}
              >
                {token}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        </IR>
      </InputGroup>
    </Container>
  );
};
