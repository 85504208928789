import React from 'react';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import styled from 'styled-components';
import blueShield from '../../assets/color-logos/blue-shield.svg';
import blueCoin from '../../assets/color-logos/blue-coin.svg';
import blueCase from '../../assets/color-logos/blue-case.svg';
import blueBridge from '../../assets/color-logos/blue-bridge.svg';
import grayShield from '../../assets/transparent-logos/gray-shield.svg';
import grayCoin from '../../assets/transparent-logos/gray-coin.svg';
import grayCase from '../../assets/transparent-logos/gray-case.svg';
import grayBridge from '../../assets/transparent-logos/gray-bridge.svg';

const Container = styled.div`
  display: flex;
  align-items: center !important;
  background: rgba(53, 57, 91, 0.20);
  border-radius: 5px;
  margin: auto;
  padding: 5px;
  margin-bottom: 5rem;

  @media (max-width: 499px) {
    display: none !important;
  }
`;

const Image = styled.img`
  width: 1.5rem;

  @media only screen and (max-width: 796px) {
    width: 1rem;
  }
`;

export const DesktopMenu: React.FC = () => {
  const tabText1 = 'Buy Protection';
  const tabText2 = 'Provide Protection';
  const tabText3 = 'Portfolio';
  const tabText4 = 'Bridge';

  const { pathname: menuPath } = useLocation();

  return (
    <Container>
      <RouteLink to="/buy-protection">
        <Button
          leftIcon={
            <Image
              src={menuPath === '/buy-protection' || menuPath.includes('pool')
                ? blueShield
                : grayShield
              } 
              alt="shield"
              />
            }
          bgColor={
            menuPath === '/buy-protection' || menuPath.includes('pool')
              ? '#152A59'
              : 'transparent'
          }
          _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
          _active={{ color: 'transparent' }}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
          px="var(--button-px)"
          py="var(--button-py)"
          fontSize="var(--p-font-size)"
          fontFamily="Helvetica"
          fontWeight="500"
          textColor={
            menuPath === '/buy-protection' || menuPath.includes('pool')
              ? 'var(--components-primary-blue)'
              : '#8893AF'
          }
          background="transparent"
          height="3rem"
          tabIndex={0}
          // className="menu-button"
        >
          {tabText1}
        </Button>
      </RouteLink>
      <RouteLink to="/provide-protection">
        <Button
          leftIcon={
            <Image
              src={menuPath === '/provide-protection' || menuPath.includes('vault')
                ? blueCoin
                : grayCoin
              }
              alt="coin"
            />
          }
          bgColor={
            menuPath === '/provide-protection' || menuPath.includes('vault')
              ? '#152A59'
              : 'transparent'
          }
          _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
          _active={{ color: 'transparent' }}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
          px="var(--button-px)"
          py="var(--button-py)"
          fontSize="var(--p-font-size)"
          fontFamily="Helvetica"
          fontWeight="500"
          textColor={
            menuPath === '/provide-protection' || menuPath.includes('vault')
              ? 'var(--components-primary-blue)'
              : '#8893AF'
          }
          background="transparent"
          height="3rem"
          tabIndex={0}
          // className="menu-button"
        >
          {tabText2}
        </Button>
      </RouteLink>
      <RouteLink to="/portfolio">
        <Button
          leftIcon={
            <Image src={menuPath === '/portfolio' ? blueCase : grayCase} alt="case" />
          }
          bgColor={
            menuPath === '/portfolio'
              ? '#152A59'
              : 'transparent'
          }
          _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
          _active={{ color: 'transparent' }}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
          px="var(--button-px)"
          py="var(--button-py)"
          fontSize="var(--p-font-size)"
          fontFamily="Helvetica"
          fontWeight="500"
          textColor={
            menuPath === '/portfolio'
              ? 'var(--components-primary-blue)'
              : '#8893AF'
          }
          background="transparent"
          height="3rem"
          tabIndex={0}
          // className="menu-button"
        >
          {tabText3}
        </Button>
      </RouteLink>
      <RouteLink to="/bridge">
        <Button
          leftIcon={
            <Image src={menuPath === '/bridge' ? blueBridge : grayBridge} alt="bridge" />
          }
          bgColor={
            menuPath === '/bridge'
              ? '#152A59'
              : 'transparent'
          }
          _hover={{ textDecoration: 'none', filter: 'brightness(85%)' }}
          _active={{ color: 'transparent' }}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
          px="var(--button-px)"
          py="var(--button-py)"
          fontSize="var(--p-font-size)"
          fontFamily="Helvetica"
          fontWeight="500"
          textColor={
            menuPath === '/bridge'
              ? 'var(--components-primary-blue)'
              : '#8893AF'
          }
          background="transparent"
          height="3rem"
          tabIndex={0}
          // className="menu-button"
        >
          {tabText4}
        </Button>
      </RouteLink>
    </Container>
  );
};
