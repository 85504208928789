import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useConfig } from './Config';
import { Logger } from '../utils/logger';

export type HeartbeatContextValue = {
  pulse: bigint;
  sendPulse(): void;
};
const HeartbeatContext = React.createContext<HeartbeatContextValue>({
  pulse: BigInt(0),
  sendPulse() {},
});

export function useHeartbeat() {
  return useContext(HeartbeatContext);
}

export const HeartbeatProvider: React.FC<any> = ({ children }) => {
  const config = useConfig();
  const [heartbeat, setHeartbeat] = useState(BigInt(0));

  useEffect(() => {
    const interval = setInterval(() => {
      setHeartbeat((hb) => hb + BigInt(1));
    }, config.heartbeatFrequencyMs);

    return () => {
      clearInterval(interval);
    };
  }, [config.heartbeatFrequencyMs]);

  useEffect(() => {
    Logger.info(`Heartbeat (${heartbeat.toString()}) -`, new Date());
  }, [heartbeat]);

  const sendPulse = useRef(() => {
    setHeartbeat((p) => p + BigInt(1));
  });

  const value = useMemo(
    () => ({
      pulse: heartbeat,
      sendPulse: sendPulse.current,
    }),
    [heartbeat]
  );

  return (
    <HeartbeatContext.Provider value={value}>
      {children}
    </HeartbeatContext.Provider>
  );
};
