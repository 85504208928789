import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  components: {
    Progress: {
      baseStyle: {
        track: {
          bg: '#d2d2d250',
        },
        filledTrack: {
          bg: '#ffffff',
        },
      },
    },
    Switch: {
      baseStyle: {
        container: {
          width: '2.5rem',
          marginLeft: '-5px',
        },
        track: {
          _checked: {
            bg: 'var(--components-primary-blue)',
          },
          _focus: {
            boxShadow: 'none',
          },
          width: '2.5rem',
          marginLeft: '-5px',
        },
        thumb: {
          _checked: {
            transform: 'translateX(1.5rem)',
          },
        },
      },
    },
  },
});
