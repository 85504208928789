/* eslint-disable class-methods-use-this */
import { Fraction } from '@risk-harbor/subsea-sdk';
import React, { useContext, useEffect } from 'react';
import { EnvLoader } from '../utils/env';
import { Logger } from '../utils/logger';
import { Maybe } from '../utils/maybe';

const ConfigContext = React.createContext<Config | null>(null);

export interface Config {
  heartbeatFrequencyMs: number;
  cmsBaseUrl: Maybe<string>;
  defaultSlippage: Fraction;
  backendUrl: string
}

function getConfig(): Config {
  const envLoader = new EnvLoader(process.env);

  try {
    const heartbeatFrequencyMs = envLoader.loadNumberMaybe('REACT_APP_HEARTBEAT_FREQUENCY_MS').getOrElse(() => 10_000);
    const backendUrl = envLoader.loadUrl('REACT_APP_BACKEND_URL');

    const cmsBaseUrl = envLoader.loadUrlMaybe('REACT_APP_CMS_BASE_URL');

    return {
      heartbeatFrequencyMs,
      cmsBaseUrl,
      defaultSlippage: {
        numerator: BigInt(1),
        denominator: BigInt(100),
      },
      backendUrl
    };
  } catch (e) {
    Logger.error(e);
    throw e;
  }
}

export function useConfig(): Config {
  const config = useContext(ConfigContext);
  if (config === null) {
    throw new Error('Config context is not initialized');
  }

  return config;
}

export function ConfigProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const config = getConfig();

  useEffect(() => {
    Logger.info('App initialized with config:', config);
  }, [config]);

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}
