import { Address } from "viem";
import { UnderWritingToken, underwritingToken } from "./UnderwritingToken";
import { VaultStatus } from "./constants";

export interface VaultType {
    address: Address,
    underWritingToken: UnderWritingToken,
    network: string,
    name: string,
    status: VaultStatus,
    expiry: string,
    chainId: number
}

export const activeVault: VaultType = 
    {
        address: '0x92c75b1c1C189C2D3627f873EE2d551233CDF323',
        underWritingToken: underwritingToken,
        network: "KarakTestnet",
        name: "Vault1",
        status: VaultStatus.Open,
        expiry: "Apr. 5. 2024",
        chainId: 2511
    };

 export const expiredVaults: VaultType[] = []; //[{
    // address: '0xa85233C63b9Ee964Add6F2cffe00Fd84eb32338f',
    // underWritingToken: underwritingToken,
    // network: "KarakDevnet",
    // name: "Vault1",
    // status: VaultStatus.Expired,
    // expiry: "Sep. 13. 2024",
    // chainId: 901
// }
// ];