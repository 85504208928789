import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Spinner,
  Text,
} from '@chakra-ui/react';
import ethLogo from '../../../assets/network-logos/eth.svg';
import optLogo from '../../../assets/network-logos/optimism.svg';
import arbLogo from '../../../assets/network-logos/arbitrum.svg';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { MultiTokenInput } from '../../OrderBox/MultiTokenInput';
import { Maybe } from '../../../utils/maybe';
import { Logger } from '../../../utils/logger';
import { performDepositERC20, performDepositEth } from '../../../actions/onboarding-deposit';
import { startWithdrawERC20FromL2, startWithdrawEthFromL2 } from '../../../actions/offboarding-withdraw';
import { decimalFormat, mapRawAmountToUi } from '../../../utils/helpers';
import { useKarakBalances, useL1Balances } from '../../../hooks/userBalances';
import { useNotifications } from '../../../notifications';
import { FixedPointNumber } from '../../../utils/fixedPoint';
import { ToolTip } from '../../ToolTip/ToolTip';
import { NetworkDetails, ToastTitles } from '../../../constants/constants';

const Header = styled(ModalHeader)`
  color: #c0c0c0;
  font-size: var(--h2-font-size) !important;
  font-weight: 500 !important;

  @media only screen and (max-width: 499px) {
    max-width: 225px;
  }
`;

const ButtonFlexbox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubHeading = styled.p`
  color: #c0c0c0;
  font-size: 0.9rem;
  margin-right: auto;
  margin-bottom: 1rem;
`;

const Flexbox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const NetworkBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid transparent;
  width: 30%;
`;

const NetworkImg = styled.img`
  width: 3rem;
  margin-bottom: 0.5rem;
`;

const NetworkName = styled.p`
  color: #c0c0c0;
  font-size: 0.85rem;
  font-weight: 600;
`;

const InfoFlex = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-top: 1.5rem;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #0F255B;
  padding: 0.75rem 1rem 0.75rem 1rem;
`;

const InfoText = styled.p`
  color: #c0c0c0;
  font-size: 0.9rem;
  font-weight: 600;
`;

interface Props {
  setPage: React.Dispatch<React.SetStateAction<boolean[]>>;
  action: string;
}

export const DepositWithdraw: React.FC<Props> = ({ setPage, action }) => {
  const [amount, setAmount] = useState<Maybe<string>>(Maybe.none());
  const [asset, setAsset] = useState<string>('ETH');
  const [loading, setLoading] = useState<boolean>(false);
  const { chain } = useNetwork();
  const { address } = useAccount();
  const { switchNetworkAsync } = useSwitchNetwork();
  const notifications = useNotifications();
  const actionHeading = action.charAt(0).toUpperCase() + action.slice(1);

  const { karakBalances } = useKarakBalances(address);
  const { ethBalance: l1EthBalance, wstETHBalance: l1wstETHBalance} = useL1Balances(address);

  const depositERC20 = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await performDepositERC20(amount, switchNetworkAsync, chain?.id, notifications);
      setLoading(false);
      setAmount(Maybe.none());
    } catch (e) {
      setLoading(false);
      Logger.error('Error depositing ERC20 (wstETH) onto Karak network', e);
      notifications.error(
        ToastTitles.BridgeDepositWstETH.errorMsg,
        <Text>Check console for error</Text>
      );
    }
  }, [amount, switchNetworkAsync, chain?.id, notifications]);

  const depositEth = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await performDepositEth(amount, switchNetworkAsync, chain?.id, notifications);
      setLoading(false);
      setAmount(Maybe.none());
    } catch (e) {
      setLoading(false);
      Logger.error('Error depositing ETH onto Karak network:', e);
      notifications.error(
        ToastTitles.BridgeDepositEth.errorMsg,
        <Text>Check console for error</Text>
      );
    }
  }, [amount, switchNetworkAsync, chain?.id, notifications]);

  const beginWithdrawERC20 = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await startWithdrawERC20FromL2(amount, switchNetworkAsync, chain?.id, notifications);
      setLoading(false);
      setAmount(Maybe.none());
    } catch (e) {
      setLoading(false);
      Logger.error('Error:', e);
    }
  }, [amount, switchNetworkAsync, chain?.id, notifications]);

  const beginWithdrawEth = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await startWithdrawEthFromL2(amount, switchNetworkAsync, chain?.id, notifications);
      setLoading(false);
      setAmount(Maybe.none());
    } catch (e) {
      setLoading(false);
      Logger.error('Error:', e);
    }
  }, [amount, switchNetworkAsync, chain?.id, notifications]);

  return (
    <>
      <Header>
        {actionHeading} Assets
      </Header>
      <ModalCloseButton
        style={{ color: '#d7d7d7', marginTop: 8 }}
        _focus={{ outline: 'none' }}
        _hover={{ filter: 'brightness(65%)' }}
      />
      <ModalBody mb={5}>
        <SubHeading>
          Choose a network to {action} assets {action === 'deposit' ? 'from' : 'to'}.
        </SubHeading>
        <Flexbox>
          <NetworkBox className="network-div" tabIndex={0} onClick={() => {}}>
            <NetworkImg src={ethLogo} alt="eth" />
            <NetworkName>Ethereum</NetworkName>
          </NetworkBox>
          <NetworkBox className="network-div-disabled" tabIndex={0}>
            <NetworkImg src={optLogo} alt="optimism" />
            <NetworkName>Optimism</NetworkName>
          </NetworkBox>
          <NetworkBox className="network-div-disabled" tabIndex={0}>
            <NetworkImg src={arbLogo} alt="arbitrum" />
            <NetworkName>Arbitrum</NetworkName>
          </NetworkBox>
        </Flexbox>
        <MultiTokenInput
          actionType={action}
          asset={asset}
          tokens={['ETH', 'wstETH']}
          amount={amount.string()}
          maxAmount={action === 'withdraw'
            ?  
              asset === 'ETH'
              ? FixedPointNumber.fromRaw(BigInt(karakBalances.ethBalance), 18).toString()
              : FixedPointNumber.fromRaw(BigInt(karakBalances.wstETHBalance), 18).toString()
            : asset === 'ETH'
              ? FixedPointNumber.fromRaw(BigInt(l1EthBalance), 18).toString()
              : FixedPointNumber.fromRaw(BigInt(l1wstETHBalance), 18).toString()
          }
          onChange={(v) => setAmount(v.trim() ? Maybe.from(v) : Maybe.none())}
          setAsset={setAsset}
          disabled={false}
        />
        <InfoFlex>
          <InfoBox>
            <InfoText>
              Wallet Balance
              <ToolTip tooltipText={NetworkDetails.WB} />
            </InfoText>
            <InfoText style={{ marginLeft: 'auto' }}>
              {
                asset === 'ETH'
                ? decimalFormat(mapRawAmountToUi(l1EthBalance, 18))
                : decimalFormat(mapRawAmountToUi(l1wstETHBalance, 18))
              }
                {' '}{asset}
            </InfoText>
          </InfoBox>
          <InfoBox>
            <InfoText>
              Account Balance
              <ToolTip tooltipText={NetworkDetails.AB} />
            </InfoText>
            <InfoText style={{ marginLeft: 'auto' }}>
              {
                asset === 'ETH'
                ? decimalFormat(mapRawAmountToUi(karakBalances.ethBalance, 18))
                : decimalFormat(mapRawAmountToUi(karakBalances.wstETHBalance, 18))
              }
                {' '}{asset}
            </InfoText>
          </InfoBox>
          <InfoBox>
            <InfoText>
              Estimated {actionHeading} Time
              <ToolTip tooltipText={action === 'deposit' ? NetworkDetails.EDT : NetworkDetails.EWT} />
            </InfoText>
            <InfoText style={{ marginLeft: 'auto' }}>1 min(s)</InfoText>
          </InfoBox>
        </InfoFlex>
        <ButtonFlexbox>
          <Button
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="#0F255B"
            borderRadius="5px"
            fontSize="var(--p-font-size)"
            onClick={() => {
              setPage([true, false]);
            }}
            width="48%"
            marginTop="2.5rem"
          >
            Back
          </Button>
          <Button
            isDisabled={amount.string() === ''}
            _hover={{
              textDecoration: 'none',
              filter: 'brightness(85%)',
            }}
            _active={{ color: 'transparent' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            px="var(--button-px)"
            py="var(--button-py)"
            textColor="white"
            background="var(--components-primary-blue)"
            borderRadius="5px"
            fontSize="var(--p-font-size)"
            onClick={
              action === 'withdraw'
              ?  
                asset === 'ETH' ? beginWithdrawEth : beginWithdrawERC20
              : asset === 'ETH' ? depositEth : depositERC20
            }
            width="48%"
            marginTop="2.5rem"
          >
            {loading ? <Spinner /> : `${actionHeading} ${asset}`}
          </Button>
        </ButtonFlexbox>
      </ModalBody>
    </>
  );
};
