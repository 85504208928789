import { Table, Td, Th, Tr, Box, Thead, Tbody, Button, Spinner } from '@chakra-ui/react';
import styled from 'styled-components';
import { UnderwriterPositionItem } from '../../types/types';
import { PaginationHOC } from './PaginationHOC';
import { ToolTip } from '../ToolTip/ToolTip';
import { InvestingDetails, VaultStatus } from '../../constants/constants';
import { getTokenIcon } from '../../utils/token-icon';
import emptyTokenIcon from '../../assets/empty-token.png';
import { Logger } from '../../utils/logger';
import { useWithdraw } from '../../actions/Withdraw';
import { useState } from 'react';
import { Address, useNetwork, useSwitchNetwork } from 'wagmi';
import { Maybe } from '../../utils/maybe';

const STable = styled(Table)`
  border-collapse: separate !important;
  border-spacing: 0 1rem !important;

  tr td:first-child {
    border-top-left-radius: 8px;
  }
  tr td:last-child {
    border-top-right-radius: 8px;
  }
  tr td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr td:last-child {
    border-bottom-right-radius: 8px;
  }
`;

const STr = styled(Tr)`
  background-color: var(--table-background-blue);
  border-radius: 8px;
  height: 5rem;
`;

const STh = styled(Th)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  color: var(--text-secondary-gray);
`;

const STd = styled(Td)`
  color: var(--text-primary-white);
  font-weight: 600;
  font-size: 14px;
`;

const Image = styled.img`
  width: 25px;
  margin-right: 7px;
`;

const TokenSymbol = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: var(--text-primary-white);
`;

const HFlexbox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FlexBox = styled.div`
  display: flex !important;
  column-gap: 8px !important;

  @media only screen and (max-width: 1448px) {
    flex-direction: column !important;
    column-gap: 0px !important;
  }
`;

// const Text = styled.p`
//   font-size: var(--h3-font-size);
//   color: var(--text-secondary-gray);
//   font-weight: 600;

//   @media only screen and (max-width: 499px) {
//     font-size: var(--p-font-size);
//   }
// `;

interface Props {
  items: UnderwriterPositionItem[];
}



export const PositionTable = PaginationHOC<Props>(({ items }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingVaultAddress, setLoadingVaultAddress] = useState<Address>();
  const network = useNetwork()
  const switchNetwork = useSwitchNetwork();
  let withdraws = new Map(items.map((position) => {return [position.address, useWithdraw(position.address)]}));

  return (
    <Box overflowX="auto">
      <STable variant="unstyled">
        <Thead>
          <Tr>
            <STh>
              <FlexBox>
                UNDERWRITING
                <ToolTip tooltipText={InvestingDetails.UND} position />
              </FlexBox>
            </STh>
            <STh textAlign="right">
              <FlexBox>
                SHARES
                <ToolTip tooltipText={InvestingDetails.SHARES} position />
              </FlexBox>
            </STh>
            <STh>
              <FlexBox>
                NETWORK
                <ToolTip tooltipText={InvestingDetails.NET} position />
              </FlexBox>
            </STh>
            <STh>
              <FlexBox>
                VAULT NAME
                <ToolTip tooltipText={InvestingDetails.NAME} position />
              </FlexBox>
            </STh>
            <STh>
              <FlexBox>
                STATUS
                <ToolTip tooltipText={InvestingDetails.STATUS} position />
              </FlexBox>
            </STh>
            <STh>
              <FlexBox>
                EXPIRY DATE
                <ToolTip tooltipText={InvestingDetails.EXP} position />
              </FlexBox>
            </STh>
            <STh>
              <FlexBox>
                WITHDRAW
                <ToolTip tooltipText={InvestingDetails.WITHDRAW} position />
              </FlexBox>
            </STh>
          </Tr>
        </Thead>
        {items.filter((position) => parseFloat(position.shares) > 0).map((position: UnderwriterPositionItem, index) => (
          <Tbody key={index}>
            <STr>
              <STd>
                <HFlexbox>
                  <Image
                    src={getTokenIcon(
                      position.network,
                      position.underwritingTokenInfo.address
                    )}
                    alt=""
                    onError={(e) => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (e.target as any).src = emptyTokenIcon;
                    }}
                  />
                  <TokenSymbol>
                    {position.underwritingTokenInfo.symbol}
                  </TokenSymbol>
                </HFlexbox>
              </STd>
              <STd textAlign="right">{position.shares}</STd>
              <STd>{position.network}</STd>
              <STd>{position.name}</STd>
              <STd>
                {position.status}
              </STd>
              <STd>{position.expiry}</STd>
              <STd>
                <Button
                  isDisabled={position.status !== VaultStatus.Expired}
                  borderWidth={1} textColor="white"
                  background="var(--components-primary-blue)"
                  fontSize="var(--p2-font-size)" borderColor={"var(--border-blue)"}
                  onClick={async () => {
                    if (position.network === network.chain?.name) {
                      switchNetwork.switchNetworkAsync?.(position.chainId);
                    }
                    setLoadingVaultAddress(position.address);
                    if (!isLoading) {
                      setIsLoading(true);
                      const _withdraw = withdraws.get(position.address)?.required("Withdraw not loaded");
                      try {
                        const res = await _withdraw?.perform();
                        const txHash = res?.required("Withdraw not successful");
                        if(Maybe.from(txHash).isSome()) position.shares = "0";
                        Logger.info("Withdraw Tx Hash: ", txHash);
                      } catch { }
                      setIsLoading(false);
                    }
                  }
                  }
                >{isLoading && loadingVaultAddress === position.address? (<Spinner />) : (<>Withdraw</>)}</Button>
              </STd>
              {/* TODO(zak): for later, when we re-enable rollover */}
              {/* <STd borderRight="1px solid var(--border-blue)">
                {position.subscriptionStatus ===
                SubscriptionStatus.NotSubscribed ? (
                  <SubscribeModal
                    vaultName={position.name}
                    vault={position.vault}
                    vaultNetwork={position.network}
                  />
                ) : (
                  <UnsubscribeModal
                    vaultName={position.name}
                    vault={position.vault}
                    vaultNetwork={position.network}
                  />
                )}
              </STd> */}
            </STr>
          </Tbody>
        ))}
      </STable>
    </Box>
  );
});
