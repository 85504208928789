import React from "react";
import styled from "styled-components";
import {
    Tabs,
    TabPanels,
    TabPanel,
    useDisclosure,
} from "@chakra-ui/react";
import { Protect } from "./Protect";
import { Deposit } from "./Deposit";
import { NetworkGuard } from "./NetworkGuard";
import { useAccount } from "wagmi";
import { OnboardingModal } from "../Modals/OnboardingModal";

const Container = styled.div`
    position: sticky;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(251, 251, 255, 0.15);
    border-radius: 5px;
    max-width: 27rem;
    width: 46%;
    margin-left: auto;
    height: fit-content !important;

    @media only screen and (max-width: 1149px) {
        margin: 1rem auto 2rem auto;
        width: 100%;
    }
`;

interface Props {
    type: string;
    networkMismatch: boolean;
    network: string;
}

export const OrderBox: React.FC<Props> = ({
    type,
    networkMismatch,
    network,
}) => {
    const account = useAccount();
    const {
        onOpen: onOnboardingOpen,
        isOpen: isOnboardingOpen,
        onClose: onOnboardingClose,
    } = useDisclosure();

    return (
        <Container>
            <Tabs variant="unstyled">
                <TabPanels>
                    <TabPanel>
                        {networkMismatch && account.address ? (
                            <NetworkGuard network={network} />
                        ) : (
                            <>
                                {type === "pool" ? (
                                    <Protect onConnect={onOnboardingOpen} />
                                ) : (
                                    <Deposit onConnect={onOnboardingOpen} />
                                )}
                            </>
                        )}
                    </TabPanel>
                    <OnboardingModal
                        isOpen={isOnboardingOpen}
                        onClose={onOnboardingClose}
                    />
                </TabPanels>
            </Tabs>
        </Container>
    );
};

OrderBox.defaultProps = {};
