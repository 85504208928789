import { useState, useEffect } from "react";
import { Address } from "viem";
import { getUserKarakBalances, getUserL1Balances } from "../utils/userBalances";
import { Logger } from "../utils/logger";
import { useHeartbeat } from "../contexts";


export function useKarakBalances(address: Address | undefined) {
  const [karakBalances, setKarakBalances] = useState({
    ethBalance: '0',
    wstETHBalance: '0',
  });
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { pulse } = useHeartbeat();

  useEffect(() => {
    const fetchKarakBalances = async () => {
      if (!address) return;
      try {
        const { ethBalance, wstETHBalance } = await getUserKarakBalances(address);
        if (ethBalance === '0' && wstETHBalance === '0') setShowOnboarding(true);
        setKarakBalances({ ethBalance, wstETHBalance });
      } catch (error) {
        Logger.error('Error fetching Karak balances:', error);
      }
    }

    fetchKarakBalances();
  }, [address, pulse]);

  return { karakBalances, showOnboarding };
}

export function useL1Balances(address: Address | undefined) {
  const [l1Balances, setl1Balances] = useState({
    ethBalance: '0',
    wstETHBalance: '0',
  });

  useEffect(() => {
    const fetchL1Balances = async () => {
      if (!address) return;
      try {
        const { ethBalance, wstETHBalance } = await getUserL1Balances(address);
        setl1Balances({ ethBalance, wstETHBalance });
      } catch (error) {
        Logger.error('Error fetching Karak balances:', error);
      }
    }

    fetchL1Balances();
  }, [address]);

  return l1Balances;
}
