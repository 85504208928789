/* eslint-disable no-underscore-dangle */
import styled from "styled-components";
import { Maybe } from "../../utils/maybe";
import { Flex, NumberInput, NumberInputField } from "@chakra-ui/react";
import { ToolTip } from "../ToolTip/ToolTip";


const InputBox: typeof NumberInput = styled(NumberInput)`
  margin-bottom: 0.5rem;
  text-align: left;
  width: 100%;
  color: var(--text-primary-white);
  border-radius: 5px !important;
  box-shadow: none !important;
`;

const InputField: typeof NumberInputField = styled(NumberInputField)`
  width: 100%;
  text-align: left;
  border: 1px solid rgba(251, 251, 255, 0.15) !important;
  height: 3.5rem !important;
  padding-right: 10px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
`;

const TextLabel = styled.p`
  font-size: var(--p-font-size);
  color: #D7D7D7;
  font-family: Helvetica Neue 500;
  font-weight: 500;
  margin-right: 0.5rem;
`;

interface Props {
    validatorIndex: string,
    setValidatorIndex: React.Dispatch<React.SetStateAction<Maybe<string>>>
}

export const PurchaseInput: React.FC<Props> = ({
    validatorIndex,
    setValidatorIndex
}) => {
    return (
        <Flex direction={"column"} marginTop={2}>
            <Flex alignItems={"center"} marginBottom={"0.5rem"}>
                <TextLabel>Your Validator Index</TextLabel>
                <ToolTip position={true} tooltipText={"Enter your validator index, you can find your validator index by searching your validator address on https://beaconcha.in/"}/*{NetworkDetails.GAB}*/ />
            </Flex>
            <InputBox
                value={validatorIndex}
                clampValueOnBlur
                isDisabled={false}
                onChange={(v) => {
                    try {
                        if (Number.isNaN(Number(v))) {
                            return;
                        }
                    } catch {
                        return;
                    }
                    setValidatorIndex(v.trim() ? Maybe.from(v) : Maybe.none());
                }}
            >
                <InputField placeholder="0" />
            </InputBox>
        </Flex>
    );
}