import { useState } from "react";
import { useConfig, useHeartbeat } from "../contexts";
import { Action } from "../utils/action";
import { Maybe } from "../utils/maybe";
import { waitForTxToMine } from "../utils/transaction";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { FixedPointNumber } from "../utils/fixedPoint";
import { Result } from "../utils/result";
import { useNotifications } from "../notifications";
import { Text } from "@chakra-ui/react";
import { Logger } from "../utils/logger";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { TxExplorerLink } from "../components/TxExplorerLink";
import { Tx, TxHash } from "../actions/shared";
import { ToastTitles } from "../constants/constants";
import { getContract } from "@wagmi/core";
import { Address, Hash } from "viem";
import {
  vaultAbi as vaultContractAbi
} from "../constants/abis/Vault";
import { underWritingTokenAbi as underWritingERC20Abi } from "../constants/abis/UnderwritingToken";
import { getConversionData } from "../utils/conversionParams";
import { underwritingToken } from "../constants/UnderwritingToken";

interface Deposit extends Tx {
  setDepositAmount(val: FixedPointNumber): void;
}

const PROVIDER_NOT_LOADED_MESSAGE = "Provider not loaded in useDeposit";
const SIGNER_NOT_LOADED_MESSAGE = "Signer not loaded in useDeposit";
const WALLET_NOT_LOADED_MESSAGE = "Wallet not loaded in useDeposit";

export function useDeposit(vaultContractAddress: Address): Maybe<Deposit> {
  const [depositAmount, setDepositAmount] = useState<FixedPointNumber>(
    FixedPointNumber.zero
  );
  const account = useAccount();
  const publicClient = usePublicClient();
  const heartbeat = useHeartbeat();
  const walletClient = useWalletClient();
  const notifications = useNotifications();
  const depositAction = new Action<TxHash>();
  const config = useConfig();

  // ERC20#approve
  depositAction
    .addStep({
      async call(): Promise<TxHash> {
        const s = Maybe.from(walletClient.data).required(
          SIGNER_NOT_LOADED_MESSAGE
        );
        const undrErc20 = getContract({
          abi: underWritingERC20Abi,
          address: underwritingToken.address,
          walletClient: s,
        });
        const txHash = await undrErc20.write.approve([
          vaultContractAddress,
          depositAmount.toBigInt(),
        ]);

        return txHash;
      },
      async waitForSatisfaction(txHash: Hash): Promise<void> {
        const p = Maybe.from(publicClient).required(
          PROVIDER_NOT_LOADED_MESSAGE
        );
        const txHashLink = makeTxExplorerLink(publicClient.chain, txHash);
        await waitForTxToMine(p, txHash);

        notifications.success(
          "ERC20 Approval Successful",
          txHashLink
            .map((l) => (
              <TxExplorerLink explorerName={l.name} explorerUrl={l.url} />
            ))
            .getOrElse(() => <></>)
        );
      },
      async satisfied(): Promise<boolean> {
        const owner = Maybe.from(account.address).required(
          WALLET_NOT_LOADED_MESSAGE
        );
        const undrErc20 = getContract({
          abi: underWritingERC20Abi,
          address: underwritingToken.address,
        });
        const _allowance: bigint = (await undrErc20.read.allowance([
          owner,
          vaultContractAddress,
        ])) as bigint;
        return _allowance >= depositAmount.toBigInt();
      },
    })
    // RHVault#deposit
    .addStep({
      async call() {
        const s = Maybe.from(walletClient.data).required(
          SIGNER_NOT_LOADED_MESSAGE
        );
        const vaultContract = getContract({
          abi: vaultContractAbi,
          address: vaultContractAddress,
          walletClient: s,
        });
        const conversionData = await getConversionData(config);
        const txHash = await vaultContract.write.deposit([
          depositAmount.toBigInt(),
          conversionData.conversionFactor,
          conversionData.conversionFactorExpiration,
          conversionData.signature,
        ]);

        heartbeat.sendPulse(); // send a heartbeat pulse to refresh data after deposit
        return txHash;
      },
      async waitForSatisfaction(txHash): Promise<void> {
        const p = Maybe.from(publicClient).required(
          PROVIDER_NOT_LOADED_MESSAGE
        );
        const txHashLink = makeTxExplorerLink(publicClient.chain, txHash);
        await waitForTxToMine(p, txHash);
        notifications.success(
          ToastTitles.Deposit.successMsg,
          txHashLink
            .map((l) => (
              <TxExplorerLink explorerName={l.name} explorerUrl={l.url} />
            ))
            .getOrElse(() => <></>)
        );
      },
      async satisfied(): Promise<boolean> {
        // terminal step
        return false;
      },
    });

  return Maybe.some<Deposit>({
    setDepositAmount(amount: FixedPointNumber) {
      if (amount.toBigInt() !== depositAmount.toBigInt()) {
        setDepositAmount(amount);
      }
    },
    async perform(): Promise<Result<TxHash, Error>> {
      let res: Result<TxHash, Error> = Result.error(
        new Error("Deposit had 0 actions")
      );

      while (!depositAction.completed) {
        res = await depositAction.step();
        if (res.isError) {
          Logger.error(
            "Transaction Failed",
            res.mapErr((e) => e).getOrElse(() => new Error())
          );
          notifications.error(
            ToastTitles.Deposit.errorMsg,
            <Text>Check console for error</Text>
          );
          return res;
        }
      }

      return res;
    },
  });
}
