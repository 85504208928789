import { useHeartbeat } from "../contexts";
import { Action } from "../utils/action";
import { Maybe } from "../utils/maybe";
import { waitForTxToMine } from "../utils/transaction";
import { usePublicClient, useWalletClient } from "wagmi";
import { Result } from "../utils/result";
import { useNotifications } from "../notifications";
import { Text } from "@chakra-ui/react";
import { Logger } from "../utils/logger";
import { makeTxExplorerLink } from "../utils/txNotifications";
import { TxExplorerLink } from "../components/TxExplorerLink";
import { Tx, TxHash } from "../actions/shared";
import { getContract } from "@wagmi/core";
import { Address } from "viem";
import {
  vaultAbi as vaultContractAbi
} from "../constants/abis/Vault";

interface Withdraw extends Tx {
}

const PROVIDER_NOT_LOADED_MESSAGE = "Provider not loaded in useWithdraw";
const SIGNER_NOT_LOADED_MESSAGE = "Signer not loaded in useWithdraw";
// const WALLET_NOT_LOADED_MESSAGE = "Wallet not loaded in useWithdraw";

export function useWithdraw(vaultContractAddress: Address): Maybe<Withdraw> {
  const publicClient = usePublicClient();
  const heartbeat = useHeartbeat();
  const walletClient = useWalletClient();
  const notifications = useNotifications();
  const withdrawAction = new Action<TxHash>();

  withdrawAction
    .addStep({
      async call() {
        const s = Maybe.from(walletClient.data).required(
          SIGNER_NOT_LOADED_MESSAGE
        );
        const vaultContract = getContract({
          abi: vaultContractAbi,
          address: vaultContractAddress,
          walletClient: s,
        });
        const txHash = await vaultContract.write.withdraw([s.account.address]);

        heartbeat.sendPulse(); // send a heartbeat pulse to refresh data after withdraw
        return txHash;
      },
      async waitForSatisfaction(txHash): Promise<void> {
        const p = Maybe.from(publicClient).required(
          PROVIDER_NOT_LOADED_MESSAGE
        );
        const txHashLink = makeTxExplorerLink(publicClient.chain, txHash);
        await waitForTxToMine(p, txHash);
        notifications.success(
            "Withdraw Successful",
          txHashLink
            .map((l) => (
              <TxExplorerLink explorerName={l.name} explorerUrl={l.url} />
            ))
            .getOrElse(() => <></>)
        );
      },
      async satisfied(): Promise<boolean> {
        // terminal step
        return false;
      },
    });

  return Maybe.some<Withdraw>({
    async perform(): Promise<Result<TxHash, Error>> {
      let res: Result<TxHash, Error> = Result.error(
        new Error("Withdraw had 0 actions")
      );

      while (!withdrawAction.completed) {
        res = await withdrawAction.step();
        if (res.isError) {
          Logger.error(
            "Transaction Failed",
            res.mapErr((e) => e).getOrElse(() => new Error())
          );
          notifications.error(
            "Withdraw Failed",
            <Text>Check console for error</Text>
          );
          return res;
        }
      }

      return res;
    },
  });
}
