import { Config } from "../contexts";

export interface PurchaseParamsResponse{
    "riskScore": bigint,
    "coverage": bigint,
    "expiration": bigint,
    "premium": bigint, 
    "signature": string,
    "validatorIndex": bigint
}

export const getPurchaseData = async (validatorIndex: string, coverage: string, config: Config): Promise<PurchaseParamsResponse> => {
  const backendUrl = config.backendUrl;
  const expirationResponse = await fetch(backendUrl + "premium_sign_generation?" + new URLSearchParams({
    validator_index: validatorIndex,
    coverage: coverage
  }));
  const expirationResponseJson: PurchaseParamsResponse = await expirationResponse.json();
  return expirationResponseJson;
};
